import React from 'react';
import './CommonContactUsSec.css'
import { Link, NavLink } from 'react-router-dom';
const CommonContactUsSec = () => {
    return (
        <div className='container'>
            <div class="header">
                {/* <div class="inner-header flex ">
                    <a href="https://app.shabujglobal.com/register/" target='_blank' rel="noreferrer">
                        <button className='glow-on-hover vidHeaderBtn'>Contact Us</button></a>
                </div> */}
                <div className="text-"> <br />
                    <h1>Admission is Open for the Upcoming Intake</h1> <br />

                    <button class="glow-on-hover vidHeaderBtn" type="button" disabled><Link className='eventRegBtn' to='/registration'>Contact Us</Link></button>
                </div>
                <div>
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="content flex">
                <p></p>
            </div>
        </div>
    );
};

export default CommonContactUsSec;