import React from 'react';
import HeaderNav from '../../components/HeaderNav/HeaderNav';
import bg from '../../assets/blogBg.jpg'
import Footer from '../../components/Footer/Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <HeaderNav />
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">

                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Privacy Policy<span class="text-success"></span></h1>
                    </div>
                </div>
            </div>

            {/* body */}

            <div className='container'>
                <h1 className='text-center'>Shabuj Global Education Privacy Policy</h1>
                <p><strong>Effective Date:</strong> 8 July 2024</p>

                <p>
                    Shabuj Global Education ("we" or "us") is committed to protecting your privacy. This policy explains how we collect, use, disclose, and safeguard your information when you visit our website (the "Site").
                </p>

                <h2>Information We Collect</h2>
                <p>We collect information in two ways:</p>
                <ul>
                    <li>
                        <strong>Personal Data:</strong> You provide this information voluntarily when you register or participate in activities on the Site. This may include your name, address, email address, and telephone number.
                    </li>
                    <li>
                        <strong>Derivative Data:</strong> Our servers automatically collect information about your visit, such as your IP address, browser type, operating system, access times, and browsing history on the Site.
                    </li>
                </ul>
                <p>We do not collect financial information like credit card details.</p>

                <h2>How We Use Your Information</h2>
                <p>We use your information to provide a smooth and personalised experience, including:</p>
                <ul>
                    <li>Creating and managing your account</li>
                    <li>Processing your application</li>
                    <li>Communicating with you about your account or application</li>
                    <li>Fulfilling and managing applications and admissions</li>
                    <li>Personalising your future visits to the Site</li>
                    <li>Improving the Site's efficiency and operation</li>
                    <li>Analysing usage trends</li>
                    <li>Sending you Site updates</li>
                    <li>Offering new products, services, or recommendations</li>
                </ul>

                <h2>Disclosure of Your Information</h2>
                <p>We may disclose your information in limited circumstances:</p>
                <ul>
                    <li>
                        <strong>Legally Required:</strong> To comply with legal process, investigate potential policy violations, or protect the rights of others.
                    </li>
                    <li>
                        <strong>Business Transfers:</strong> In connection with mergers, acquisitions, or asset sales.
                    </li>
                    <li>
                        <strong>Third-Party Service Providers:</strong> To authorised service providers who help us operate the Site (e.g., data analysis, marketing).
                    </li>
                </ul>

                <h2>Security of Your Information</h2>
                <p>
                    We use security measures to protect your information. However, no method is foolproof, and we cannot guarantee complete security.
                </p>

                <h2>Children's Privacy</h2>
                <p>
                    We do not knowingly collect information from children under 13. If you believe we have information about a child under 13, please contact us for deletion.
                </p>

                <h2>Changes to This Policy</h2>
                <p>
                    We may update this policy. We will notify you by posting the revised policy on the Site.
                </p>

                <h2>Contact Us</h2>
                <p>If you have any questions about this policy, please contact us.</p>
            </div>

            {/* body */}




            <Footer />
        </div>
    );
};

export default PrivacyPolicy;