import React, { useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup
} from "react-simple-maps";
// const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-continents.json";
const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';
const markers = [
// likha up douwn er jonno  markerOffset & markerOffsetx
    { markerOffset: 3,markerOffsetx: 45, name: "Bangladesh", coordinates: [90.3563, 23.6850] },
    { markerOffset: 25, name: "UK", coordinates: [-0.1278, 51.5074] },
    { markerOffset: 25, name: "India", coordinates: [78.9629, 20.5937] },
    { markerOffset: -5, markerOffsetx: -20, name: "Nepal", coordinates: [84.1240, 28.3949] },
    { markerOffset: 25, name: "Nigeria", coordinates: [8.6753, 9.0820] },
    { markerOffset: 4,markerOffsetx: -26, name: "Qatar", coordinates: [51.1839, 25.3548] },
    { markerOffset: 15,markerOffsetx: 15, name: "Dubai", coordinates: [55.2708, 25.2048] },
    { markerOffset: 6,markerOffsetx: 30, name: "Vietnam", coordinates: [108.2772, 14.0583] },

];

const MapChart = () => {
    return (
        <div className="container" style={{  width: "100%" }} >
            <ComposableMap
                projection="geoMercator"
                projectionConfig={{
                    rotate: [-70, 0, 0],
                    scale: 220,
                }}
                // width={800}
                // height={600}
            >
               
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    // map color
                                    fill="#01006666"
                                    stroke="#D6D6DA"
                                />
                            ))
                        }
                    </Geographies>
                    {markers.map(({ name, coordinates, markerOffset,markerOffsetx }) => (
                        <Marker key={name} coordinates={coordinates}>
                            <circle r={6} fill="#F00" stroke="#fff" strokeWidth={4} />
                            <text
                                textAnchor="middle"
                                y={markerOffset}
                                x={markerOffsetx}
                                style={{ fontFamily: "system-ui", fill: "#0a0512" }}
                            >
                                {name}
                            </text>
                        </Marker>
                    ))}
            </ComposableMap>
        </div>
    );
};

export default MapChart;