import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';

import CommonContactUsSec from '../../Shared/CommonContactUsSec/CommonContactUsSec'

import s1 from '../../assets/scholarship/s1.png'
import s2 from '../../assets/scholarship/s2.jpg'
import s3 from '../../assets/scholarship/s3.png'

const Scolarship = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeaderNav></HeaderNav>
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">

                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Scholarship<span class="text-success"></span></h1>
                    </div>
                </div>
            </div>
            <article className='container'>
            <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>Education is the foundation of success</h1>
                </div>
                
                <p>Unfortunately, not everyone has the financial means to pursue their dreams of obtaining a higher education. That's where SGE comes in. As an education consulting company, SGE is dedicated to providing opportunities to those who might not have had them before, including through scholarships.</p>
                <p>SGE understands the financial burdens of pursuing a higher education, and that's why the company offers scholarships to deserving students. These scholarships are awarded based on merit and need, with the goal of providing assistance to those who may not have the financial resources to pursue their academic goals.</p>
                <p>SGE's scholarship program is open to students who are interested in pursuing higher education in any field. Whether you are interested in science, engineering, arts, or humanities, SGE wants to help you achieve your goals. By providing scholarships, SGE is able to ensure that deserving students have access to the same opportunities as those who are financially capable.</p>
                <p>The scholarship program offered by SGE is not only beneficial for the students who receive the funding, but also for the community as a whole. By investing in education, SGE is investing in the future. As more and more students are able to pursue higher education, they are better equipped to give back to their communities and contribute to society in a meaningful way.</p>
                <p>In addition to scholarships, SGE also offers education consulting services to help students navigate the complex process of applying to colleges and universities. The company's experienced consultants provide guidance and support throughout the entire process, from selecting schools to preparing applications and essays.</p>
                <p>SGE is dedicated to making education accessible to all, and the company's scholarship program is just one example of how they are doing this. By providing opportunities to deserving students, SGE is helping to create a more equitable society where everyone has the chance to succeed.</p>
                <p>In conclusion, SGE is a company that is committed to providing opportunities to students who might not have had them before. Through their scholarship program, they are helping to make education accessible to all, regardless of financial means. If you are a student looking to pursue higher education, consider applying for an SGE scholarship and take advantage of the opportunities that this education consulting company has to offer.</p>
                <h1 className='text-center'>Scholarship Available</h1>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                            <a href="https://www.commonwealthofnations.org/" target="_blank" rel="noreferrer"><img src={s1} class="img-fluid" alt="Responsive Image" /></a>
                        </div>
                        <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                            <a href="https://study-uk.britishcouncil.org/" target="_blank" rel="noreferrer"><img src={s2} class="img-fluid" alt="Responsive Image" /></a>
                        </div>
                        <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                            <a href="https://www.chevening.org/" target="_blank" rel="noreferrer"><img src={s3} class="img-fluid" alt="Responsive Image" /></a>
                        </div>
                    </div>
                </div>
            </article>

            <CommonContactUsSec />

            <Footer />
        </div>
    );
};

export default Scolarship;