import React from 'react';
import OfficeLocation from '../../components/OfficeLocation/OfficeLocation';
import MapChart from './MapChart';

const Map = () => {
    return (
        <div>
            <OfficeLocation/>
            <div className='container-fluid myServiceBg'>
                <h1 className='text-center text-white'>SGE Worldwide Office Presence</h1>
            </div>
            <MapChart/>
        </div>
    );
};

export default Map;