import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import HeaderNav from '../../components/HeaderNav/HeaderNav';
import BlogBg from '../../assets/blogBg.jpg'
import { Link } from 'react-router-dom';
import { Card, CardGroup } from 'react-bootstrap';

import b1 from '../../assets/Blogs Image/blog1.jpg'
import b2 from '../../assets/Blogs Image/blog2.jpg'
import b3 from '../../assets/Blogs Image/blog3.jpg'
import CommonContactUsSec from '../../Shared/CommonContactUsSec/CommonContactUsSec';

const ParantBlog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='mt-5'>
            <HeaderNav></HeaderNav>
            <div class="container bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${BlogBg})`
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">BLOG <span class="text-success">&</span> News</h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <CardGroup>
                    <Card className='m-3'>
                        <Card.Img variant="top" src={b1} />
                        <Card.Body>
                            <Card.Title>Choosing the Right UK University and Course: Factors to Consider</Card.Title>
                            <Card.Text>
                                <p>Embarking on higher education in the UK is a crucial journey that sets the stage for your future career and personal development...</p>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Link to="/blogone"><div className="text-center"><button className='btn btn-primary'>Read More</button></div></Link>
                        </Card.Footer>
                        <Card.Footer>
                            <small className="text-muted">Published on January 07, 2024</small>
                        </Card.Footer>
                    </Card>

                    <Card className='m-3'>
                        <Card.Img variant="top" src={b2} />
                        <Card.Body>
                            <Card.Title>Networking in University: A Shabuj Global Student's Guide</Card.Title>
                            <Card.Text>
                                <p>Embarking on your university journey in the UK is an exciting chapter in your life, not just academically but also for personal and professional growth...</p>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Link to="/blogtwo"><div className="text-center"><button className='btn btn-primary'>Read More</button></div></Link>
                        </Card.Footer>
                        <Card.Footer>
                            <small className="text-muted">Published on January 07, 2024</small>
                        </Card.Footer>
                    </Card>

                    {/* <Card className='m-3'>
                        <Card.Img variant="top" src={b3} />
                        <Card.Body>
                            <Card.Title>Why take free consultants and help from applying to flying with Shabuj Global Education to your dream country for education?</Card.Title>
                            <Card.Text>
                                <p>Shabuj Global Education running and expanding successfully for almost 13 years until now and having 150+ ...</p>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Link to="/blogthree"><div className="text-center"><button className='btn btn-primary'>Read More</button></div></Link>
                        </Card.Footer>
                        <Card.Footer>
                            <small className="text-muted">Published on March 10, 2023 </small>
                        </Card.Footer>
                    </Card> */}

                    
                </CardGroup>

                <CommonContactUsSec />
                
            </div>
            <Footer></Footer>
        </div>
    );
};

export default ParantBlog;