import React, { useEffect } from 'react';

import BlogBg from '../../assets/blogBg.jpg'
import img1 from '../../assets/events/event4.jpg'
import img2 from '../../assets/events/event5.jpg'
import img3 from '../../assets/events/event6.jpg'
import img4 from '../../assets/events/event7.jpg'
import img5 from '../../assets/events/event8.jpg'
import img6 from '../../assets/events/event9.jpg'
// import img7 from '../../assets/events/event10.jpg'
import img8 from '../../assets/events/event11.jpg'
// import img7 from '../../assets/events/event10.png'
import img9 from '../../assets/events/event15.png'
import img10 from '../../assets/events/event16.png'
import img11 from '../../assets/events/event17.jpg'
import img12 from '../../assets/events/event18.jpg'
import img13 from '../../assets/events/event20.jpg'
import img14 from '../../assets/events/event22.png'
import img15 from '../../assets/events/event24.png'
import img16 from '../../assets/events/event26.png'
import img17 from '../../assets/events/event27.jpg'
import img18 from '../../assets/events/event28.jpg'
import img19 from '../../assets/events/event29.jpg'
import img20 from '../../assets/events/event33.jpeg'
import img21 from '../../assets/events/event34.jpeg'
import img22 from '../../assets/events/event37.png'
import img23 from '../../assets/events/event38.jpg'
import img24 from '../../assets/events/event39.jpg'
import img25 from '../../assets/events/event41.jpg'
import img26 from '../../assets/events/event43.jpg'
import img28 from '../../assets/events/event46.jpeg'
import img29 from '../../assets/events/event47.jpeg'
import img30 from '../../assets/events/event49.jpeg'
import img31 from '../../assets/events/event50.jpeg'
import img32 from '../../assets/events/event52.jpg'
import img33 from '../../assets/events/event54.png'
import img34 from '../../assets/events/event56.jpg'
import img35 from '../../assets/events/event59.jpeg'
import img36 from '../../assets/events/event62.png'
import img37 from '../../assets/events/event63.png'
import img38 from '../../assets/events/event66.png'
import img39 from '../../assets/popup/openday.jpg'



import CountdownTimerOne from '../../Shared/CountdownTimer/CountdownTimerOne/CountdownTimerOne';
import CountdownTimerTwo from '../../Shared/CountdownTimer/CountdownTimerTwo/CountdownTimerTwo';
import CountdownTimerThree from '../../Shared/CountdownTimer/CountdownTimerThree/CountdownTimerThree';
import CountdownTimerFour from '../../Shared/CountdownTimer/CountdownTimerFour/CountdownTimerFour';
import { Link } from 'react-router-dom';
import './EventsSg.css'

const EventsSg = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='mt-5'>
            <div class="mt-5 container-fluid bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${BlogBg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Events <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>

            <div className='text-center pb-3'>
                <h1>Upcoming Events</h1>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        🌟 Join Us for an Open Day in Dhanmondi! 🌟</b>
                                    </h6>
                                    <p class="card-text text-justify">We're excited to announce the <b>grand opening of our new branch in Dhanmondi</b>! To celebrate, we're hosting a special Open Day for students interested in higher education. If you're looking to explore opportunities and gain valuable insights into your future, this event is for you!🚀
                                    </p>
                                    <p class="card-text text-justify">🎓  What to expect:
                                    </p>
                                    <p class="card-text text-justify">✨ Information sessions on various higher education options
                                    </p>
                                    <p class="card-text text-justify">✨ One-on-one consultations with our expert advisors
                                    </p>
                                    <p class="card-text text-justify">✨ Scholarship and financial aid guidance
                                    </p>
                                    <p class="card-text text-justify">✨ Networking with like-minded students
                                    </p>

                                    <p>Whether you're just starting your journey or ready to take the next step, this Open Day will provide the tools and knowledge you need to succeed. Don't miss out on this fantastic opportunity to shape your future
                                    </p>


                                    {/* <span className="newsWeight">ABUJA (6th of July, 2024)</span> */}
                                    <p><b>🗺️ Location:-</b> <b>759, Delvista Fuljhuri(Lift-5)
                                        Satmosjid Road, Dhanmondi, Dhaka-1207</b></p>

                                    <p><b>📞 Contact-  01701808655, 01321182535</b></p>

                                    <p><b>⏰ Time: 10:00am - 06:00pm | September 1st to 5th</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        {/* <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span > */}
                                        <CountdownTimerOne />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img39} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>












            <div className='container-fluid myServiceBg'>
                <h1 className='text-center text-white'>Previous Events</h1>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-4">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGNigeria22%2Fvideos%2F1022238315934857%2F&show_text=false&width=267&t=0" width="267" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        🌟 Step into a World of Academic Excellence! 🌟</b>
                                    </h6>
                                    <p class="card-text text-justify">Get ready to embark on a transformative journey at our exclusive Education Expo, where endless possibilities await you! 🚀
                                    </p>
                                    <p class="card-text text-justify">🎓 Explore the Elite of UK Universities: With our assistance, delve into the realm of top-tier educational institutions.
                                    </p>
                                    <p class="card-text text-justify">🔍 Tailor Your Learning Experience: Determine the perfect academic course level that aligns with your aspirations.
                                    </p>
                                    <p class="card-text text-justify">🤝 Personalized Consultations with Universities: Connect directly with academic experts for tailored guidance.
                                    </p>
                                    <p class="card-text text-justify">💡 Discover Tailored Courses and Scholarship Opportunities: Unlock pathways designed specifically for your needs.
                                    </p>
                                    <p class="card-text text-justify">📚 Ensure Your Eligibility: Confirm your qualifications meet the requirements for your academic aspirations.
                                    </p>

                                    <p><b>Join us</b> at our events, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆.
                                    </p>


                                    <span className="newsWeight">ABUJA (6th of July, 2024)</span>
                                    <p><b>🗺️ Location:-</b> <b>09 Senanga Street, Wuse Zone 5. </b></p>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerOne /> */}
                                    </div> <br />
                                    <p><b>📞 Contact-  +44 7768697734 | +234 903 226 2854 </b></p>

                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>📧 Email: applications.nigeria@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Explore Your Path at the International Education Fair 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 We are excited to inform you that numerous <b>prestigious universities</b> have recently <Link to="/photos" ><b>visited</b></Link> our offices, providing invaluable insights and <b>exclusive offers available solely through Shabuj Global Education.</b> 🌍✨</p>

                                    <p><b>Join us</b> on <span className="newsWeight">May 18th</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>The Peninsula Chittagong,</b> 486/B, CDA Avenue, O.R. Nizam Road, Bulbul Center, Chattogram 4100</p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 18th May, 2024</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo/> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img36} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-4">
                                <img class="w-100" src={img35} alt='' />
                            </div>

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>📚 Embark on Your Educational Odyssey at the Multi-Destination Education Expo! 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 We are thrilled to announce that a multitude of esteemed universities have recently graced our premises, unveiling invaluable insights and exclusive opportunities exclusive to Shabuj Global Education. 🌍✨</p>

                                    <p><b>Join us</b> on <span className="newsWeight">MAY 25th</span> at our event, where you can seize these <b>exceptional opportunities</b> to shape your academic journey and pave the way for a prosperous future 🏆. Don't delay, secure your spot now to ensure your participation in this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Sheraton Dhaka,</b> 44 Kemal Ataturk Ave, Dhaka 1213</p>
                                    <p><b>📞 Contact- +880 1321-182535</b></p>
                                    <p><b>📅 Date: 25th may, 2024</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerOne/> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Explore Your Path at the International Education Fair 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 We are excited to inform you that numerous <b>prestigious universities</b> have recently <Link to="/photos" ><b>visited</b></Link> our offices, providing invaluable insights and <b>exclusive offers available solely through Shabuj Global Education.</b> 🌍✨</p>

                                    <p><b>Join us</b> on <span className="newsWeight">May 18th</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>The Peninsula Chittagong,</b> 486/B, CDA Avenue, O.R. Nizam Road, Bulbul Center, Chattogram 4100</p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 18th May, 2024</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo/> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img36} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img37} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        We're thrilled to announce an exclusive <b>International Education Expo</b>, where the doors to a world of possibilities will swing wide open for you! 🚀</b>
                                    </h6>
                                    <p class="card-text text-justify">🎓 Discover Universities: With our support, explore top-tier educational institutions.
                                    </p>
                                    <p class="card-text text-justify">🔍 Determine the Ideal Academic Course Level: Customize your learning to match your goals.
                                    </p>
                                    <p class="card-text text-justify">🤝 Direct Consultation with Universities: Receive personalized guidance from academic experts.
                                    </p>
                                    <p class="card-text text-justify">💡 Identify Suitable Courses and Scholarships: Access opportunities perfectly suited to your needs.
                                    </p>
                                    <p class="card-text text-justify">📚 Validate Your Eligibility: Confirm that you meet the requirements for your desired academic journey.

                                    </p>

                                    <p><b>Join us</b> on <span className="newsWeight">May 15th</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Grand Palace,</b>📍 Location: Jollarpar, Sylhet, Bangladesh </p>
                                    <p><b>📞 Contact-  (+880) 1321 182 528 </b></p>
                                    <p><b>📅 Date: <span className="newsWeight">15th May </span>, 2024</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerThree /> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        𝐅𝐨𝐥𝐤𝐬 𝐨𝐟 𝐃𝐡𝐚𝐤𝐚 !</b>
                                    </h6>
                                    <p class="card-text text-justify">Don't let this opportunity slip away, grab it and make it count! Meet <b>Ms. Neeta Tyagi</b> from <b>Queen's University Belfast</b> one of the top-ranking and Russel group Universities in UK.
                                    </p>


                                    <p><b>Join us</b> on <span className="newsWeight">May 15th</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> Nagar Lutfun Homes, House No.11 Road No. 17, Block D Banani Dhaka Bangladesh</p>
                                    <p><b>📞 Contact-  (+880) 1321 182 535 </b></p>
                                    <p><b>📅 Date: <span className="newsWeight">1st March </span>, 2024</b></p>
                                    <p><b>⏰ Time: 02:00pm - 03:30pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerThree /> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img38} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img34} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        𝐅𝐨𝐥𝐤𝐬 𝐨𝐟 𝐃𝐡𝐚𝐤𝐚 !</b>
                                    </h6>
                                    <p class="card-text text-justify">Don't let this opportunity slip away, grab it and make it count! Meet <b>Ms. Neeta Tyagi</b> from <b>Queen's University Belfast</b> one of the top-ranking and Russel group Universities in UK.
                                    </p>


                                    <p><b>Join us</b> on <span className="newsWeight">March 1st</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> Nagar Lutfun Homes, House No.11 Road No. 17, Block D Banani Dhaka Bangladesh</p>
                                    <p><b>📞 Contact-  (+880) 1321 182 535 </b></p>
                                    <p><b>📅 Date: <span className="newsWeight">1st March </span>, 2024</b></p>
                                    <p><b>⏰ Time: 04:15pm - 05:30pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerOne /> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        🌟 Step into a World of Academic Excellence! 🌟</b>
                                    </h6>
                                    <p class="card-text text-justify">Get ready to embark on a transformative journey at our exclusive Education Expo, where endless possibilities await you! 🚀
                                    </p>
                                    <p class="card-text text-justify">🎓 Explore the Elite of UK Universities: With our assistance, delve into the realm of top-tier educational institutions.
                                    </p>
                                    <p class="card-text text-justify">🔍 Tailor Your Learning Experience: Determine the perfect academic course level that aligns with your aspirations.
                                    </p>
                                    <p class="card-text text-justify">🤝 Personalized Consultations with Universities: Connect directly with academic experts for tailored guidance.
                                    </p>
                                    <p class="card-text text-justify">💡 Discover Tailored Courses and Scholarship Opportunities: Unlock pathways designed specifically for your needs.
                                    </p>
                                    <p class="card-text text-justify">📚 Ensure Your Eligibility: Confirm your qualifications meet the requirements for your academic aspirations.
                                    </p>

                                    <p><b>Join us</b> at our events, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆.
                                    </p>

                                    <span className="newsWeight">LAGOS (February 17th)</span>
                                    <p><b>🗺️ Location:-</b> <b>Ibis Hotel, Toyin Street, Ikeja</b></p>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo /> */}
                                    </div>
                                    <span className="newsWeight">ABUJA (February 24th)</span>
                                    <p><b>🗺️ Location:-</b> <b>Ibro Hotel, 1960 Michael Okpara Street, Wusa</b></p>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerThree /> */}
                                    </div> <br />
                                    <p><b>📞 Contact-  +44 7768697734 | +234 903 226 2854 </b></p>

                                    <p><b>⏰ Time: 10:00am - 04:00pm</b></p>
                                    <p>📧 Email: applications.nigeria@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGNigeria22%2Fvideos%2F763523945278013%2F&show_text=false&width=380&t=0" width="380" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img33} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>
                                        We're thrilled to announce an exclusive Education Expo, where the doors to a world of possibilities will swing wide open for you! 🚀</b>
                                    </h6>
                                    <p class="card-text text-justify">🎓 Discover Premier UK Universities: With our support, explore top-tier educational institutions.
                                    </p>
                                    <p class="card-text text-justify">🔍 Determine the Ideal Academic Course Level: Customize your learning to match your goals.
                                    </p>
                                    <p class="card-text text-justify">🤝 Direct Consultation with Universities: Receive personalized guidance from academic experts.
                                    </p>
                                    <p class="card-text text-justify">💡 Identify Suitable Courses and Scholarships: Access opportunities perfectly suited to your needs.
                                    </p>
                                    <p class="card-text text-justify">📚 Validate Your Eligibility: Confirm that you meet the requirements for your desired academic journey.

                                    </p>

                                    <p><b>Join us</b> on <span className="newsWeight">February 12nd</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Hotel Noorjahan Grand,</b>📍 Location: Waves 1, Ritz Tower, Dargah Gate, Sylhet, Bangladesh </p>
                                    <p><b>📞 Contact-  (+880) 1321 182 521 | (+880) 1321 182 528 </b></p>
                                    <p><b>📅 Date: <span className="newsWeight">12th February </span>, 2024</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>


                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerOne /> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"> 🌟 𝐄𝐱𝐜𝐥𝐮𝐬𝐢𝐯𝐞 𝐒𝐩𝐨𝐭 𝐀𝐬𝐬𝐞𝐬𝐬𝐦𝐞𝐧𝐭 𝐚𝐭 𝐒𝐲𝐦𝐩𝐡𝐨𝐧𝐲 𝐇𝐞𝐢𝐠𝐡𝐭𝐬-𝐒𝐲𝐥𝐡𝐞𝐭 𝐰𝐢𝐭𝐡 𝐌𝐫. 𝐋𝐢𝐚𝐦 𝐒𝐭𝐨𝐭𝐭  𝐟𝐫𝐨𝐦 University of Bedfordshire  ! </h6>


                                    <p>We are thrilled to announce an exclusive opportunity for <b>aspiring students in Sylhet, Bangladesh</b>, to meet <b>Mr. Liam Stott</b> from the <b>University of Bedfordshire.</b>
                                    </p>

                                    <p>
                                        <b>Mr. Liam Stott</b> will be available to provide insights into the exciting academic opportunities at the <b>University of Bedfordshire</b>. Whether you have questions about courses, scholarships, or campus life, <b>this is your chance to get personalized answers</b>.
                                    </p>

                                    <p>For appointments and inquiries, please contact:</p>
                                    <p><b>🗺️ Location:-</b><b>SGE Sylhet Office:</b> 3rd Floor, Symphony Heights, Baruth Khana, East Zindabazar, Sylhet Bangladesh</p>
                                    <p><b>📞 Contact-  (+880) 17 0180 8655</b></p>
                                    <p><b>📅 Date: <span className="newsWeight">8th February </span>, 2024</b></p>
                                    <p><b>⏰ Time: 11:00am - 02:00pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo /> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img31} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img32} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"> 𝐄𝐱𝐜𝐢𝐭𝐢𝐧𝐠  𝐀𝐧𝐧𝐨𝐮𝐧𝐜𝐞𝐦𝐞𝐧𝐭 ! 🎓</h6>


                                    <p>We are delighted to invite you to a special event on 17th January 2024, from 12:00 pm to 02:00 pm at the Shabuj Global Education Chittagong Branch. Meet the <b>University of Portsmouth</b> representative,<b> Mr. Md. Nuruzzaman.</b>
                                    </p>
                                    <p><b>𝐌𝐎𝐈 𝐀𝐜𝐜𝐞𝐩𝐭𝐞𝐝 !</b></p>

                                    <p>
                                        Discover: <br />
                                        🌍 Globally recognized degrees <br />
                                        💼 In-demand career skills <br />
                                        🌏 Diverse student community <br />
                                        🏖️ Beautiful Portsmouth city
                                    </p>
                                    <p>For appointments and inquiries, please contact:</p>
                                    <p><b>🗺️ Location:-</b><b>SGE Chittagong Office:</b> R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh</p>
                                    <p><b>📞 Contact-  (+880) 1321182544</b></p>
                                    <p><b>📅 Date: <span className="newsWeight">January 17th </span>, 2024</b></p>
                                    <p><b>⏰ Time: 12:00pm - 02:00pm</b></p>
                                    <p>📧 Email: om.bd@shabujglobal.com</p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo /> */}
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"> 🎓 <b>Multi-Destination Education Fair in INDIA</b> 🌍 </h6>


                                    <p>Ready to turn your doubts into dreams? Join us at the Multi-destination Education Fair 2023! ✨
                                        Here's a unique opportunity for you to clear all your doubts with <b>university experts!</b> 🎓
                                    </p>

                                    <p>Why Be There?<br />
                                        👉🏻Meet University <b>Representatives</b> <br />
                                        👉🏻Free Registration<br />
                                        👉🏻Up to<b> 15 lakh in Scholarships</b><br />
                                        👉🏻<b>Spot Offer letter</b> (within 24 hours)<br />

                                        👉🏻Special Prize for SG Applicants</p>


                                    <p><b>🗺️ Location:-</b>  <b>Holiday Inn, Cochin, INDIA</b></p>
                                    <p><b>📞 Contact- +91 81292 55564</b></p>
                                    <p><b>📅 Date: <span className="newsWeight">28th October</span>, 2023</b></p>
                                    {/* <p><b>⏰ Time: 10:00am - 02:00pm</b></p> */}
                                    {/* <p>Entry- FREE ! (Registration Required)</p> */}

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerOne /> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img28} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img30} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"> 🎓 <b>Exciting Catch-Up Session for Sylhet's future students !</b> 🌍 </h6>


                                    <p>Join us on <b>October 30th 2023</b>, from 10:30 am to 2:00 pm as The <b>University College Birmingham</b>  hosts a special session to help you secure <b>admission in 2024</b>.🎓
                                    </p>

                                    <p>Why Choose UCB?<br />
                                        ✅ Upto <b>4000GBP </b>scholarship <br />
                                        ✅ Quick Offer <br />
                                        ✅ Accredited by the <b>University of Warwick</b> <br />
                                        ✅ <b>Ranked 108th</b> by Times Higher Education<br />
                                        ✅ IELTS score 6.0/5.5 accepted<br />
                                        ✅ <b>Affordable</b> Tuition Fees<br />
                                        ✅ Part-time and post-study work permit</p>


                                    <p><b>🗺️ Location:-</b><b>SGE Sylhet Office:</b> 3rd Floor, Symphony Heights, Baruth Khana, East Zindabazar, Sylhet Bangladesh</p>
                                    <p><b>📞 Contact- +880 1321-182528</b></p>
                                    <p><b>📅 Date: <span className="newsWeight">30th October </span>, 2023</b></p>
                                    <p><b>⏰ Time: 10:30am - 02:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerTwo /> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">

                                <div class="card-block">
                                    <h6 class="card-title"> 🎓 <b>Exciting news for Chattogram future students !</b> 🌍 </h6>


                                    <p>Join us on <b>November 4th 2023</b>, from 11:00 am to 2:30 pm as The <b>University College Birmingham</b>  hosts a special session to help you secure <b>admission in 2024</b>.🎓
                                    </p>

                                    <p>Why Choose UCB?<br />
                                        ✅ Accredited by the <b>University of Warwick</b> <br />
                                        ✅ <b>Ranked 108th</b> by Times Higher Education<br />
                                        ✅ IELTS score 6.0/5.5 accepted<br />
                                        ✅ Affordable Tuition Fees<br />
                                        ✅ Part-time and post-study work permit</p>


                                    <p><b>🗺️ Location:-</b>SGE Chittagong Office,📍 Location: R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh</p>
                                    <p><b>📞 Contact- +880 1321182544</b></p>
                                    <p><b>📅 Date: <span className="newsWeight">4th November </span>, 2023</b></p>
                                    <p><b>⏰ Time: 11:00am - 02:30pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                        {/* <CountdownTimerThree /> */}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <img class="w-100" src={img29} alt='' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-4">
                                <img class="w-100" src={img24} alt='' />
                            </div>

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Explore Your Path at the Multi-Destination Education Expo 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 We are excited to inform you that numerous <b>prestigious universities</b> have recently <Link to="/photos" ><b>visited</b></Link> our offices, providing invaluable insights and <b>exclusive offers available solely through Shabuj Global Education.</b> 🌍✨</p>

                                    <p><b>Join us</b> on <span className="newsWeight">October 21st</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead 🏆. Act swiftly, <b>register now</b> to reserve your place for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Hotel Sarina,</b> Plot #27, Road #17, Banani C/A, Dhaka</p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 21st October, 2023</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"> <b>📣An Exciting <span className="newsWeight">QA</span> Open Day at our <span className="newsWeight">Dhaka Office!</span> 🌟</b> </h6>


                                    <p>This is your chance to explore study opportunities with renowned universities and meet our esteemed guests! Don't miss out on this incredible opportunity! 🎓
                                    </p>
                                    <p>
                                        <b>🏫 Universities:
                                            QA Higher Education: (Ulster University, Northumbria University  Solent University, Southampton, University of South Wales, University of Roehampton)
                                        </b>
                                    </p>
                                    <p>
                                        👥 Special Guests: <b>Mr. Mislu Miah</b> - Regional Director & <b>Mr. Abdul Hamid</b> - Senior Regional Manager

                                    </p>

                                    <p><b>🗺️ Location:-</b> Nagar Lutfun Homes, House: 11, Block: D, Road: 17, Banani, Dhaka. </p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: <span className="newsWeight">12th October</span>, 2023</b></p>
                                    <p><b>⏰ Time: 10:00am - 02:00pm</b></p>
                                    {/* <p>Entry- FREE ! (Registration Required)</p> */}

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img25} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-4">
                                <img class="w-100" src={img26} alt='' />
                            </div>

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Spot Application day [ Coventry University ]</b> </h6>
                                    <p class="card-text text-justify">Discover a world of opportunities with <b>Sadia Afrin Prova</b> at our exciting Spot Application day - your gateway to the <b>Coventry University</b> for a bright future! 🚀</p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Chittagong Office,</b>📍 Location:  R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh</p>
                                    <p><b>📞 Contact- +880 1321182544</b></p>
                                    <p><b>📅 Date: 11th October, 2023</b></p>
                                    <p><b>⏰ Time: 12:00pm - 02:00pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Global Futures: Explore Your Path at the Multi-Destination Education Expo 🌍</b> </h6>


                                    <p>Join us on <span className="newsWeight">October 2nd</span> at our event, where you can seize these <b>exceptional opportunities</b> for your academic journey, ensuring a promising future lies ahead. Don't hesitate; <b>register now</b> and secure your spot for this promising event!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Hotel Noorjahan Grand,</b>📍 Location: Waves 1, Ritz Tower, Dargah Gate, Sylhet, Bangladesh </p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 2nd October, 2023</b></p>
                                    <p><b>⏰ Time: 10:00am - 06:00pm</b></p>
                                    <p>Entry- FREE ! (Registration Required)</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img22} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img23} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"><b>🎓 Spot Assessment Event [ University Of Portsmouth ] </b> </h6>
                                    <p class="card-text text-justify">Discover a world of opportunities with <b>Md. Nuruzzaman</b> at our exciting Spot Assessment Event - your gateway to the <b>University of Portsmouth</b> for a bright future! 🚀</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔ Unravel Scholarship Opportunities - Find out how you can secure financial aid for your dream education.

                                        <br /> ✔100% Cashback on IELTS - Take the first step towards your international journey with IELTS and get a fantastic cashback offer!
                                    </p>
                                    <p><b>🗺️ Location:-</b> <b>SGE Dhaka Office,</b> Nagar Lutfun Homes, House No. 11, Road No. 17, Block - D, Banani, Dhaka</p>
                                    <p><b>📞 Contact- +880 1321-182535</b></p>
                                    <p><b>📅 Date: 3rd October, 2023</b></p>
                                    <p><b>⏰ Time: 11:00pm - 06:00pm</b></p>
                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Apply to Canterbury Christ Church University  with Shabuj Global Education! 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 Are you excited to embark on an incredible educational journey ? Look no further ! We're thrilled to invite you to our exclusive Spot Application event with MD. JUBAYED UDDIN. , Business Development Manager, InUni Global. 🌍✨</p>

                                    <p>Key Benefits Include:
                                        <br /> 📚 Key Benefits that Await You!
                                        <br /> 💰 Exclusive Scholarships!
                                        <br /> 📜 Quick Offer Letter!
                                        <br /> 🤝 Direct Interaction!
                                        <br /> 🌍 OIETC? Accepted!
                                        <br /> 🔒 No Hidden Charges!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Chittagong Office,</b>📍 Location:  R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh</p>
                                    <p><b>📞 Contact- +880 1321182544</b></p>
                                    <p><b>📅 Date: 1st October, 2023</b></p>
                                    <p><b>⏰ Time: 03:30am - 05:30pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img21} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">


                            <div class="col-md-4">
                                <img class="w-100" src={img20} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Apply to Canterbury Christ Church University  with Shabuj Global Education! 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 Are you excited to embark on an incredible educational journey ? Look no further ! We're thrilled to invite you to our exclusive Spot Application event with MD. JUBAYED UDDIN. , Business Development Manager, InUni Global. 🌍✨</p>

                                    <p>Key Benefits Include:
                                        <br /> 📚 Key Benefits that Await You!
                                        <br /> 💰 Exclusive Scholarships!
                                        <br /> 📜 Quick Offer Letter!
                                        <br /> 🤝 Direct Interaction!
                                        <br /> 🌍 OIETC? Accepted!
                                        <br /> 🔒 No Hidden Charges!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Dhaka Office,</b>📍 Location: Nagar Lutfun Homes House No.11 Road No. 17, Block D, Banani, Dhaka. </p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 20th September, 2023</b></p>
                                    <p><b>⏰ Time: 10:30am - 02:30pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Apply to De Montfort University with Shabuj Global Education! 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 Are you excited to embark on an incredible educational journey? Look no further! We're thrilled to invite you to our exclusive Spot Assessment event with <b>Ms. Ayesha Qadir</b>, the International Recruitment Officer from <b>De Montfort University.</b> 🌍✨</p>

                                    <p>Key Benefits Include:
                                        <br /> 💰 Exclusive Scholarships!
                                        <br /> 📜 Quick Offer Letter!
                                        <br /> 🤝 Direct Interaction!
                                        <br /> 🌍 OIETC? Accepted!
                                        <br /> 🔒 No Hidden Charges!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Chittaging Office,</b> R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh. Tel: (880)1321182544</p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 7th September, 2023</b></p>
                                    <p><b>⏰ Time: 10:30am - 12:30pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img18} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img16} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"><b>🎓 Nigeria Event </b> </h6>
                                    <p class="card-text text-justify">CALLING all aspiring students in <b>Abuja, Nigeria!</b> 🇳🇬 Are you dreaming of studying abroad in renowned schools from the <b>UK, Australia, Canada, Germany, and the USA?</b> 🌐✨ Look no further! Join us at the Shabuj Global Educational Expo and embark on a journey towards your academic success.</p>
                                    <p>🎓 What's in it for you?:
                                        <br /> ✔ Meet representatives from top-notch international schools.
                                        {/* <br /> ✔Receive personalized guidance on University Selection */}
                                        <br /> ✔Gain insights into various study programs and scholarships.
                                        <br /> ✔On the spot document assessment
                                        <br /> ✔Discover the world-class education opportunities that await you.
                                    </p>
                                    <p><b>🗺️ Location:-</b> No 9 Senanga Street Wuse Zone 5 FCT Abuja</p>
                                    <p><b>📞 Contact- +44 7769697734 or +2349133444409</b></p>
                                    <p><b>📅 <b>Abuja 7th</b>, <b>Lagos 8th & 9th</b>, September 2023</b></p>
                                    <p><b>⏰ Time: 10:00am</b></p>
                                    <p>Email: applications.nigeria@shabujglobal.com </p>
                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">



                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>What is "Spot Assessment Day”? </b> </h6>
                                    <p class="card-text text-justify">A Spot Assessment Day is an event where educational institutions or their partners assess applicants on the spot, providing immediate decisions for admission and further process etc.</p>

                                    <p>If you are Dreaming of Studying in the UK, 📚✈️ Join us on our Chattogram Office Spot Assessment Day and take a step closer to your dream university!</p>

                                    <p>🎯 What to Expect
                                        <br /> ✅ One-on-one guidance from UK university representatives.
                                        <br /> ✅ Spot assessments for admission eligibility.
                                        <br /> ✅ Scholarship information.
                                        <br /> ✅ Visa application assistance.
                                        <br /> ✅ Career counseling.
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Chittaging Office,</b> R.I. Tower (level 3) 23/A MM Ali Road Golpahar moor, Chattogram, Bangladesh. Tel: (880)1321182544</p>
                                    <p><b>📞 Contact- +880 1321-182544 </b></p>
                                    <p><b>📅 Date: 9th September, 2023</b></p>
                                    <p><b>⏰ Time: 11:00am - 05:00pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img19} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img17} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Apply to De Montfort University with Shabuj Global Education! 🌍</b> </h6>
                                    <p class="card-text text-justify">🚀 Are you excited to embark on an incredible educational journey? Look no further! We're thrilled to invite you to our exclusive Spot Assessment event with <b>Ms. Ayesha Qadir</b>, the International Recruitment Officer from <b>De Montfort University.</b> 🌍✨</p>

                                    <p>Key Benefits Include:
                                        <br /> 💰 Exclusive Scholarships!
                                        <br /> 📜 Quick Offer Letter!
                                        <br /> 🤝 Direct Interaction!
                                        <br /> 🌍 OIETC? Accepted!
                                        <br /> 🔒 No Hidden Charges!
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>SGE Dhaka Office,</b> Nagar Lutfun Homes, House No. 11, Road No. 17, Block - D, Banani, Dhaka</p>
                                    {/* <p><b>📞 Contact- +880 1321-182535</b></p> */}
                                    <p><b>📅 Date: 5th September, 2023</b></p>
                                    <p><b>⏰ Time: 10:00am - 01:00pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Multi Destination Education Fair</b> </h6>
                                    <p class="card-text text-justify">Greetings from Shabuj Global Education Bangladesh.</p>
                                    <p>Are you Planning to study in The <b>UK, USA, Canada & Australia?</b></p>
                                    <p>Grab the opportunity to fulfill your dream at Rest Inn Hotel, Moulvibazar in Sylhet on <b>24th of August</b>.</p>

                                    <p>Key Benefits Include:
                                        <br /> ✔Learn about scholarship opportunities
                                        <br /> ✔Study Gap Accepted
                                        <br /> ✔100% Cashback on IELTS
                                    </p>

                                    <p><b>🗺️ Location:-</b> <b>Rest Inn Hotel, Moulvibazar in Sylhet</b></p>
                                    <p><b>📞 Contact- +88 01321-182528</b></p>
                                    <p><b>📅 Date: 24th August, 2023</b></p>
                                    <p><b>⏰ Time: 10:00am - 05:00pm</b></p>
                                    <p><b>Entry- FREE !</b></p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">


                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F686506123377434%2F&show_text=false&width=267&t=0" width="267" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img14} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"><b>🎓 Spot Assessment Event [ University Of Birmingham ] </b> </h6>
                                    <p class="card-text text-justify">Discover a world of opportunities with <b>Niharika Gupta</b> at our exciting Spot Assessment Event - your gateway to the <b>University of Birmingham</b> for a bright future! 🚀</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔ Unravel Scholarship Opportunities - Find out how you can secure financial aid for your dream education.
                                        {/* <br /> ✔Receive personalized guidance on University Selection */}
                                        <br /> ✔100% Cashback on IELTS - Take the first step towards your international journey with IELTS and get a fantastic cashback offer!
                                    </p>
                                    <p><b>🗺️ Location:-</b> <b>SGE Dhaka Office,</b> Nagar Lutfun Homes, House No. 11, Road No. 17, Block - D, Banani, Dhaka</p>
                                    <p><b>📞 Contact- +880 1321-182535</b></p>
                                    <p><b>📅 Date: 6th August, 2023</b></p>
                                    <p><b>⏰ Time: 12:30pm - 04:30pm</b></p>
                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">🎓 <b>Spot Assessment Event [ University Of Dundee ]</b> </h6>
                                    <p class="card-text text-justify">Discover a world of opportunities with <b>Jonathan Fortune</b> at our exciting Spot Assessment Event - your gateway to the <b>University of Dundee</b> for a bright future! 🚀</p>
                                    <p><b>🗺️ Location:-</b> <b>SGE Dhaka Office,</b> Nagar Lutfun Homes, House No. 11, Road No. 17, Block - D, Banani, Dhaka</p>
                                    <p><b>📞 Contact- +880 1321-182535</b></p>
                                    <p><b>📅 Date: 6th August, 2023</b></p>
                                    <p><b>⏰ Time: 11:00am - 12:30pm</b></p>
                                    <p>Entry- FREE !</p>

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img15} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title"><b>Multi Destination Education Fair</b> </h6>
                                    {/* <p class="card-text text-justify">For those who attended023 the expo and would like to proceed with further application, you are welcome to join our Open Spot Admissions on 24th June!</p> */}
                                    <p>Key Benefits Include:
                                        <br /> ✔Learn about scholarship opportunities
                                        <br /> ✔Receive personalized guidance on University Selection
                                        <br /> ✔100% Cashback on IELTS
                                    </p>
                                    <p><b>Location-</b> <b>Hotel Valley Garden</b> Subhanighat, Sylhet</p>
                                    <p>Contact- <b>+880 1321-182528</b></p>
                                    <p><b>Date: 23th July 2023</b></p>
                                    <p><b>Time: 10am - 5pm</b></p>
                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img12} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img11} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Spot Application Day for Canada!</h6>
                                    <p class="card-text text-justify">For those who attended the expo and would like to proceed with further application, you are welcome to join our Open Spot Admissions on 24th June!</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔Learn about scholarship opportunities
                                        <br /> ✔Receive personalized guidance on University Selection
                                        <br /> ✔100% Cashback on IELTS
                                    </p>
                                    <p><b>Location-</b> <b>Dhaka Office:</b> Nagar Lutfun Homes House No.11 Road No. 17, Block D Banani Dhaka</p>
                                    <p>Contact- <b>+880 1321-182535</b></p>
                                    <p><b>Date: 24th June 2023</b></p>
                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Hey! <b>Nigeria</b>!</h6>
                                    <p class="card-text text-justify">Participate in the  <b>EDUCATIONAL EXPO</b> and get your seats booked</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔<b>Apply without</b> IELTS
                                        <br /> ✔<b>Part-time job</b> opportunity
                                        <br /> ✔Up to <b>£10000</b> Scholarship
                                        <br /> ✔<b>Zero Charge </b>From Students
                                        <br /> ✔Study Gap Accepted</p>

                                    <p><b>Location- No 9, Senanga Street, Wuse, Zone 5, Abuja</b><br />Contact- <b>+447769697734</b><br /><b>Date: 22nd June, 2023</b></p>

                                    <a href="https://shabuj-global-africa-139672368.hubspotpagebuilder.eu/shabuj-global-africa-data-form?fbclid=IwAR3QYEbjyfoya7ghiT1PK9C1R_6imoATzZoJ2V6ksSYgBx4MkP6J8x7vm98" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" >APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img10} height="300" width="600" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img9} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Hey! <b>Nigeria</b>!</h6>
                                    <p class="card-text text-justify">Participate in the  <b>EDUCATIONAL EXPO</b> and get your seats booked</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔<b>Apply without</b> IELTS
                                        <br /> ✔<b>Part-time job</b> opportunity
                                        <br /> ✔Up to <b>£10000</b> Scholarship
                                        <br /> ✔<b>Zero Charge </b>From Students
                                        <br /> ✔Study Gap Accepted</p>
                                    <p><b>Location- no 57, Bode Thomas Street, Surulere, Lagos</b></p>
                                    <p>Contact- <b>+447769697734</b></p>
                                    <p><b>Date: 20th June, 2023</b></p>
                                    <a href="https://shabuj-global-africa-139672368.hubspotpagebuilder.eu/shabuj-global-africa-data-form?fbclid=IwAR3QYEbjyfoya7ghiT1PK9C1R_6imoATzZoJ2V6ksSYgBx4MkP6J8x7vm98" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" >APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Get ready to be captivated by the educational marvels at the Biggest <b>Education Fair in Chittagong</b> on June 3rd! </h6>
                                    <p class="card-text text-justify"></p>
                                    <p>Key Benefits Include:
                                        <br /> ✔Scholarship Opportunities
                                        <br /> ✔<b>Zero Charge </b>From Students
                                        <br /> ✔Study Gap Accepted</p>
                                    <p>Location- <b>SG Chittagong Office</b> 2nd Floor, R I Tower, Beside of Robi Sheba, Gol pahar Moor, Chattogram Bangladesh Tel: (880) 13 2118 2544</p>
                                    <p><b>Date: 3rd June, 2023</b></p>
                                    {/* <p>Time- full day</p> */}
                                    {/* <p>Call Us : +01711-245409</p> */}

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    {/* <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a> */}

                                    {/* <class="glow-on-hover" class="glow-on-hover eventRegBtn " to='/registration'>
                                        Registration
                                    </class=> */}
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div className="text-center">
                                    <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F267659672291356%2F&show_text=false&width=267&t=0" width="267" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <div className="text-center">
                                    <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F1594723677689130%2F&show_text=false&width=476&t=0" width="350" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Higher Education Fair <b>(Special Edition)</b> In Sylhet Is Live on 31st May!</h6>
                                    <p class="card-text text-justify">Embark on a transformative journey that transcends borders and immerses you in the global learning experience.</p>
                                    <p>Key Benefits Include:
                                        <br /> ✔Scholarship Opportunities
                                        <br /> ✔Zero Charge From Students
                                        <br /> ✔Study Gap Accepted</p>
                                    <p>Location- <b>SG Sylhet Office</b> 3rd Floor, Symphony Heights Baruth Khana East Zindabazar Sylhet</p>
                                    <p><b>Date: 31th May, 2023</b></p>
                                    {/* <p>Time- full day</p> */}
                                    {/* <p>Call Us : +01711-245409</p> */}

                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>
                                    {/* <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a> */}

                                    {/* <class="glow-on-hover" class="glow-on-hover eventRegBtn " to='/registration'>
                                        Registration
                                    </class=> */}
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Are you ready to take the first step towards an incredible future?</h6>
                                    <p class="card-text text-justify">we've got exciting news for you! <b>Liam Stott</b>, the International Recruitment Manager of the prestigious <b>University of Bedfordshire</b>, is coming to Dhaka on <b>May 27th </b>at Shabuj Global Dhaka Office to conduct an <b>on-spot assessment</b> session for the upcoming intake on November 2023 & January 2024. We are thrilled to have Liam Stott join us for this incredible opportunity.</p>
                                    <p>Learn more about the <b>University of Bedfordshire</b> and receive expert advice from <b>Liam Scotts</b> on how to make your application stand out. With his years of experience in international student recruitment, <b>Liam Scotts</b> is the perfect person to guide you through the process and help you achieve your goals.</p>
                                    <p>Location- <b>SG Dhaka Office</b> Nagar Lutfun Homes, House 11, Rd No 17, Block D, Banani, Dhaka 1213</p>
                                    <p><b>Date: 27th May, 2023</b></p>
                                    {/* <p>Time- full day</p> */}
                                    {/* <p>Call Us : +01711-245409</p> */}




                                    <button class="glow-on-hover" type="button" disabled><Link className='eventRegBtn' to='/registration'>APPLY NOW</Link></button>





                                    {/* <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a> */}



                                    {/* <class="glow-on-hover" class="glow-on-hover eventRegBtn " to='/registration'>
                                        Registration
                                    </class=> */}

                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div className="text-center"><iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F3467476763481988%2F&show_text=false&width=269&t=0" width="269" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>





            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <div className="text-center"><iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FSGE16%2Fvideos%2F1431696250913349%2F&show_text=false&width=476&t=0" width="300" height="325" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Dear Students,</h6>
                                    <p class="card-text text-justify">Come to <b>Shabuj Global Education's</b> spectacular Higher Education Fair with us! Meet representatives from prestigious colleges and universities from countries such as the <b>USA, Canada, UK, Australia, Malta</b>, and more!</p>
                                    <p>Location- <b>Hotel Noorjahan Grand</b>, Sylhet</p>
                                    <p><b>Date: 2nd May, 2023</b></p>
                                    <p>Don't pass up this wonderful opportunity—<b>entry is free!</b> We're eager to meet you there!</p>
                                    {/* <p>Time- full day</p> */}
                                    {/* <p>Call Us : +01711-245409</p> */}
                                    <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled >APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Hey! Chattogram !</h6>
                                    <p class="card-text text-justify">We are excited to announce that <b>Mr. Martin Booth</b>, Senior Regional Manager at <b>De Montfort University</b> Leicester (DMU) will be visiting our <b>Chattogram office</b> on <b>April 18th</b>, 2023. This is a great opportunity for anyone interested in pursuing higher education to learn more about the study options available at one of the UK's leading universities.</p>
                                    <p>Don't miss out on this chance to learn more about<b> De Montfort University</b> and start planning your academic future. We look forward to seeing you on April 18th !</p>
                                    <p>Location- <b>SG Chattogram Office</b> 2nd floor, R I tower, beside Robi Sheba, Chattogram <br />Call Us : +88 01321 182 542 <br />Time: 12:00PM - 03:00pm</p>
                                    <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img8} height="300" width="600" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <div className="text-center"><iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FShabuj.india%2Fvideos%2F584390443640769%2F&show_text=false&width=273&t=0" width="273" height="476" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Participate in the event and get your seats booked</h6>
                                    <p class="card-text text-justify"><b>University of Reading</b> Open Day! Meet <b>Pooja Nalawade</b>, Regional Manager, South Asia at our Kannur Office</p>
                                    <p>Location- <b>SG India Office</b> 4th floor, Daliya Arcade NCC Road, Thalassery, Kannur</p>
                                    <p><b>Date: 4th April, 2023</b></p>
                                    <p>Time-11am</p>
                                    <p>Call Us : +918129255564, +918129255574</p>
                                    <a href="" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">

                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Dear Education Seekers,</h6>
                                    <p class="card-text text-justify">If you have a dream to study abroad for a stable career, employment opportunities & shape your life ahead you may visit a very special education fair where a number of prestigious <b>UK, USA, CANADA and AUSTRALIAN</b> Universities Representatives will be there to assist.</p>
                                    <p>Location- <b>REST INN HOTEL, Moulvibazar, Sylhet.</b></p>
                                    <p><b>Date: 21st March, 2023</b></p>
                                    <p>Time-10am To 5pm.</p>
                                    <p>You may visit anytime within 5pm</p>
                                    <p>Entry- FREE !</p>
                                    <a href="https://forms.gle/AaxuE7rgLKp6rHwy5" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img6} height="300" width="600" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img4} height="300" width="600" alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Higher Education Fair In Cox's Bazar</h6>
                                    <p class="card-text text-justify">You're invited to the first-ever Discover Your Path Higher Education Fair in <b>Cox's Bazar</b> on 18th March, 2023 at <b>UNI RESORT</b> (Opposite Ocean Paradise, Main Road, kolatali, Cox's Bazar 4700) from <b>10:00am to 6:30pm.</b> <br /> This event is a unique opportunity to engage with representatives from top universities in the <b>UK, USA, Canada, Australia, and Malta</b>. You'll have the chance to learn about a wide range of academic programs, scholarships, and admission requirements. Whether you're a high school student or a working professional, this event is your chance to explore your higher education options and discover your path to success. Join us and take the first step towards an exciting future</p>
                                    <a href="https://forms.gle/AAWL2T8AmUCNcRsy6" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title text-right">Dream Big Study Abroad Chittagong</h6>
                                    <p class="card-text text-justify">You're invited to be our Guest ! <br /> Are you ready to take the next step in your education? <br /> Attend the University of <b>Huddersfield's</b> Open Day and discover how we can help you achieve your goals. From world-class facilities to supportive academic programs, we have everything you need to succeed. <br /> See you there !</p>
                                    <a href="https://forms.gle/iYoVNb7zW5fbqbDLA" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img5} height="300" width="600" alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img1} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Higher Education Fair In Dhaka</h6>
                                    <p class="card-text text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title text-right">Dream Big Stydy Abroad Chittagong</h6>
                                    <p class="card-text text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img class="w-100" src={img3} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="card col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-4">
                                <img class="w-100" src={img2} alt='' />
                            </div>
                            <div class="col-md-8">
                                <div class="card-block">
                                    <h6 class="card-title">Higher Education Fair In Dhaka</h6>
                                    <p class="card-text text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhoz4sQPwFuK-MSwzjzPB1nz3kQcT0tTFmKEtlURZ_7i8kIQ/viewform" target={'_blank'} rel="noreferrer">
                                        <button class="glow-on-hover" type="button" disabled>APPLY NOW</button>
                                    </a>
                                    <div className='mt-3 '>
                                        <span style={{ fontWeight: "800", }} className='text-white bg-danger text-center rounded mt-3 font-weight-bold p-1'>Registration Over</span >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    );
};

export default EventsSg;





/*


CALLING all aspiring students in Abuja, Nigeria! 🇳🇬 Are you dreaming of studying abroad in renowned schools from the UK, Australia, Canada, Germany, and the USA? 🌐✨ Look no further! Join us at the Shabuj Global Educational Expo and embark on a journey towards your academic success.

- Date: Abuja 7th, Lagos 8th & 9th, September 2023
- Time: 10:00am
- Venue: No 9 Senanga Street Wuse Zone 5 FCT Abuja

🎓 What's in it for you?
- Meet representatives from top-notch international schools.
- Gain insights into various study programs and scholarships.
- On the spot document assessment 
- Discover the world-class education opportunities that await you.

Don't miss out on this incredible chance to shape your future! 🌟 Let's turn your study abroad dreams into reality. See you at the expo! 🌏🎉

Contact Us Today!

Email: applications.nigeria@shabujglobal.com 

Phone: +44 7769697734 or +2349133444409

Website: 







*/