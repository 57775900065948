import React from 'react';

import './Testimonial.css'

import { Carousel } from 'react-bootstrap';
import img1 from '../../assets/Testimonial/1.png'
import img2 from '../../assets/Testimonial/2.png'
import img3 from '../../assets/Testimonial/3.png'
import img4 from '../../assets/Testimonial/4.png'
import img5 from '../../assets/Testimonial/5.png'
import img6 from '../../assets/Testimonial/6.png'
import img7 from '../../assets/Testimonial/7.png'
import img8 from '../../assets/Testimonial/8.png'
import img9 from '../../assets/Testimonial/9.png'

const Testimonial = () => {
    return (
        <div>
            <div className='container testHeight '>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img3}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img4}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img5}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img6}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img7}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img8}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img9}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={img2}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>

                </Carousel>
            </div>
        </div>
    );
};

export default Testimonial;