import React from 'react';
import HeaderNav from '../HeaderNav/HeaderNav';
import Footer from '../Footer/Footer';
import bg from '../../assets/blogBg.jpg'
import i1 from '../../assets/career/job1.jpg'
import i2 from '../../assets/career/job2.jpg'
import i3 from '../../assets/career/job3.jpg'
import i4 from '../../assets/career/job4.jpg'
import i5 from '../../assets/career/job5.jpg'
import i6 from '../../assets/career/job6.jpg'
import i7 from '../../assets/career/job7.jpg'
import i8 from '../../assets/career/job8.jpg'
import i9 from '../../assets/career/job9.jpg'
import i10 from '../../assets/career/job10.jpg'
import i11 from '../../assets/career/job11.jpg'

import { Link } from 'react-router-dom';

const CareerCards = () => {
    return (
        <div>
            <HeaderNav></HeaderNav>
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Career <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>

            {/* all jobs cards Bangladesh and UK */}

            <section id="projects" class="projects py-6">
                <div class="container">
                    <div className='container-fluid myServiceBg slide-from-right-to-left mb-5'>
                        <h1 className='text-center text-white'>Bangladesh based Job Openings</h1>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i1} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Senior Recruitment Manager - Dhaka</h5>
                                    <p class="card-text">
                                        With a minimum of 3 years' experience, you will streamline the recruitment process, working towards set targets, and devising effective strategies to attract top talent in the educational sector.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i2} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Senior Recruitment Manager - Chattogram</h5>
                                    <p class="card-text">
                                        In this target-oriented role, you will use your experience to develop effective recruitment strategies, aiming to attract and retain the best talent in the educational sector in Chattogram.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i3} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Destination Manager Australia - Dhaka</h5>
                                    <p class="card-text">
                                        Are you an Australian graduate with an understanding of the Australian education system? In this role, you will advise students on their journey to studying in Australia, using your first-hand knowledge to guide them through the process.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i4} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Destination Manager Canada - Dhaka</h5>
                                    <p class="card-text">
                                        As a Canadian graduate, your role will be to assist students with their transition to studying in Canada. Your knowledge of the Canadian education system will be invaluable in providing guidance and advice to students.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i5} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Destination Manager UK - Dhaka</h5>
                                    <p class="card-text">
                                        UK graduates, we need your expertise. In this role, you will advise students on their educational journey to the UK. Your understanding of the UK education system will be vital in guiding students to a successful transition.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i6} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Digital Marketing Executive</h5>
                                    <p class="card-text">
                                        We are seeking a skilled and experienced Digital Marketing Executive to join our team. As a Digital Marketing Executive, you will be responsible for managing and optimizing advertising campaigns on Facebook and Google platforms.
                                        {/* <a href="article.html">Read More</a> */}
                                        <Link to='/wearehiring1'>Read More</Link>
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@hsedu.co.uk</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="projects" class="projects py-6">
                <div class="container">
                    <div className='container-fluid myServiceBg slide-from-right-to-left mb-5'>
                        <h1 className='text-center text-white'>UK based Job Openings</h1>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i7} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Senior Student Recruitment Manager</h5>
                                    <p class="card-text">
                                        Drive and manage our global student recruitment activities. Your expertise will drive student recruitment and shape strategies to attract the brightest talent from around the world. This target-driven role requires a deep understanding of international education systems and excellent networking skills.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i8} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Global Social Media Manager</h5>
                                    <p class="card-text">
                                    Take the reins of our global social media accounts. Your primary responsibility will be to create engaging content, manage and grow our online presence. Candidates should have a strong understanding of global market trends and proven experience managing multiple platforms. #SocialMediaManager #OnlinePresence
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i9} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Regional Manager - UK International Students</h5>
                                    <p class="card-text">
                                    Steer the recruitment and admissions processes for international students based in the UK. You'll serve as the crucial link between the students and Shabuj Global Education, ensuring a smooth transition from applicant to enrolled student. 
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i10} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Regional Manager - EU Students</h5>
                                    <p class="card-text">
                                    Lead our outreach to both UK-based and overseas EU students. A keen understanding of European education systems and an ability to communicate effectively across cultures are vital in this role. 
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card border-0">
                                <img src={i11} alt="" />
                                <div class="card-body">
                                    <h5 class="card-title">Student Liaison Officer</h5>
                                    <p class="card-text">
                                    Provide support to our students and team by aiding the progression of the student pipeline. You'll be instrumental in smoothing the journey from prospect to student, ensuring that everyone is informed, engaged, and ready for the next step.
                                        {/* <a href="article.html">Read More</a> */}
                                    </p>
                                    <p>
                                    To apply, send your CV and a brief cover letter introducing yourself and the role you are applying for to <b>hrm@shabujglobal.com</b>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <Footer />
        </div>
    );
};

export default CareerCards;