import React from 'react';
import { Card, CardGroup, Nav } from 'react-bootstrap';
import './Service.css'

import img1 from '../../assets/ourService/1.jpg'
import img2 from '../../assets/ourService/2.jpg'
import img3 from '../../assets/ourService/3.jpg'
import Testimonial from '../../Shared/Testimonial/Testimonial';

const Service = () => {
    return (
        <div>
            <div className='container-fluid myServiceBg slide-from-right-to-left'>
                <h1 className='text-center text-white'>Your Pathway to University Admission</h1>
            </div>
            <div class="container slide-from-right-to-left">
                <div class="row">
                    <div class=" col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-5">
                                <img class="w-100 h-100" src={img1} />
                            </div>
                            <div class="col-md-7">
                                <div class="card-block">
                                    <p class="card-text text-justify">Shabuj Global Education aka SG Education headquartered in London, England is
                                        one of the <b>BRITISH COUNCIL</b> accredited education service providers in the
                                        United Kingdom. The company has been working since 2010 with great pride and
                                        service excellence. At <b>Shabuj Global</b> we provide services to the local and
                                        international students for the UK University admission. Our dedicated team with
                                        years of experience help our clients with highest professional standards. We are
                                        partnered with 150+ universities in the UK (including the <b>Russell Group </b>
                                        universities). We provide the services to our potential students free of cost and
                                        also offer our private scholarship opportunities up to 100% fee waiver apart from
                                        the university scholarships.
                                        Every education consultancy runs on specific motivation and has target to
                                        achieve and ours say <b>“OUR STUDENTS ARE OUR REFERENCE”</b> and we shall
                                        stand by it no matter what. We maintain a track record of 100% student
                                        satisfaction from onboarding for application to the VISA approval and to have
                                        landed here in the UK, we take care of all it hassle free.</p>
                                    <a href="https://www.shabujglobal.com/registration" target='_blank' rel="noreferrer">
                                        <button className='btn btn-primary'>Contact Us</button>  </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid myServiceBg slide-from-left-to-right'>
                <h1 className='text-center text-white'>Comprehensive Services for Higher Education</h1>
            </div>
            <div class="container slide-from-left-to-right">
                <div class="row">
                    <div class=" col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-7">
                                <div class="card-block">
                                    <p class="card-text text-justify">
                                        <p>Free Career Counselling </p>
                                        <p>Free File Assessments</p>
                                        <p>Admission Processing (sorting out the best location, universities, courses,
                                            guidance in finance management, scholarships, etc.)</p>
                                        <p>Interview Preparation</p>
                                        <p>Visa Application Guidelines</p>
                                        <p>Accommodation Help</p>
                                        <p>Scholarships For Gifted Students</p>
                                    </p>
                                    <a href="https://www.shabujglobal.com/registration" target='_blank' rel="noreferrer">
                                        <button className='btn btn-primary'>Contact Us</button>  </a>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <img class="w-100 h-100" src={img2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CardGroup className='container slide-from-right-to-left mb-3' >
                <Card className='' >
                    <Card.Body className='justify-content-end'  >
                        <iframe className='container h-100' src="https://www.youtube.com/embed/TM7M0LIu1to" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className='justify-content-center '>
                        <h2 className=''>Building Bridges to Higher Education</h2>
                        <p>At Shabuj Global, we build bridges between our students and the many Institutes
                            of higher education in the UK. We take time to get to know our student
                            customers, and it is this personal touch that helps us to find the correct course
                            for them. At Shabuj Global we hope to help students make the right choices, and
                            then we help them bring those choices to realisation. The end goal is a
                            satisfactory and complete education and a student ready to face the world with
                            confidence.</p>
                    </Card.Body>
                </Card>
            </CardGroup>
            <div className='container-fluid myServiceBg slide-from-left-to-right'>
                <h1 className='text-center text-white'>Empowering Students with Personalized University Admission</h1>
            </div>
            <div className="container slide-from-left-to-right">
                <p><b>Shabuj Global Education</b> has a proven track record of assisting more than <b>120,000</b> students with over<b> 30,000+ </b>enrollments, ensuring they find admission to the right course. In some cases, we have facilitated transfers from one university to another, providing end-to-end support throughout the process. Our dedicated administrative team works closely with not only our clients but also the universities and colleges to ensure that our students receive the best possible education.</p>
            </div>
            <div className='container-fluid myServiceBg slide-from-right-to-left'>
                <h1 className='text-center text-white'>Certificates and Awards</h1>
            </div>
            <div class="container slide-from-right-to-left">
                <div class="row">
                    <div class=" col-md-12 p-3">
                        <div class="row ">
                            <div class="col-md-5">
                                <img class="img-fluid w-100 " src={img3} />
                            </div>
                            <div class="col-md-7">
                                <div class="card-block">
                                    <p class="card-text text-justify">
                                        <p>Power 100 Niminees for the contribution to <b>"Higher Education"</b> in the UK in 2017.</p>
                                        <p>Preffered partner of the university of <b>Huddersfield</b> in Bangladesh.
                                        </p>
                                        <p>Awarded many times <b>"Top Overseas Student Consultant"</b> and <b>"Certificate of outstanding recruitment".</b>
                                        </p>
                                        <p>
                                            VIP partner of many Universities.
                                        </p>
                                        <p>
                                            Most Russell Group Universities represented.
                                        </p>
                                    </p>
                                    <a href="https://www.shabujglobal.com/registration" target='_blank' rel="noreferrer">
                                        <button className='btn btn-primary'>Contact Us</button>  </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid myServiceBg slide-from-left-to-right'>
                <h1 className='text-center text-white'>Testimonial</h1>
            </div>
            <Testimonial></Testimonial>
        </div>
    );
};

export default Service;