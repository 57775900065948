import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../assets/shabujLogo.png'
import logo1 from '../../assets/rszCompanyLogo.png'
import { Link, useNavigate} from "react-router-dom";
import './HeaderNav.css'


function HeaderNav() {

    
    //  just to give a reload, nicher gula add korte hoise
    const navigate = useNavigate();
    const handleRegistrationClick = () => {
      window.location.href = '/registration'; // Simulate a full page reload
    };
    


    return (
        <div className="">
            <Navbar bg="light" expand="lg" fixed="top" >
                <Container className='p-0'>
                    <Navbar.Brand>
                        <Link to='/'>
                        <img
                            src={logo1}
                            width="309"
                            height="55"
                            className="d-inline-block align-top"
                            alt="SGE LOGO"
                        /></Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >
                        <Nav className="">
                            <Link className='myNav' to='/'>Home </Link>
                            <Link className='myNav' to='/about'>About</Link>
                            <Link className='myNav' to='/services'>Services</Link>
                            <Link className='myNav' to='/events'>Events </Link>
                            {/* <Link className='myNav' to='/agents'>Agent </Link> */}

                            {/* <Link  className='myNav'  onClick={handleRegistrationClick}>Registration </Link> */}
                            <button className='myNav navBtn' onClick={handleRegistrationClick}>Registration</button>

                    
                    
                            <Link className='myNav' to='/Universities'>Universities </Link>
                            <Link className='myNav' to='/contact'>Contact</Link>
                            <Link className='myNav' to='/blogs'>Blog</Link>
                            <a className='myNav' target='_blank' href="https://shabujglobal.uuacrm.com/" rel="noreferrer">Login</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>

    );
}
export default HeaderNav;
