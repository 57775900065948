import React from 'react';
import './WhyBest.css'
import img1 from '../../assets/why/why1.png'
import img2 from '../../assets/why/why2.png'
import img3 from '../../assets/why/why3.png'
import img4 from '../../assets/why/why4.png'

const WhyBest = () => {
    return (
        <div className=' whyBest mb-5' >
            <div class="main text-center mt-5">
                <h6 class="heading">Why We Are The Best</h6>
                <p class="container p-5 pt-0 pb-0"><b>Shabuj Global provides an excellent service to students with their admission to top quality universities and colleges in the UK. Shabuj Global helps students from all over the world. Home Students, EU students and International students have all benefitted from our help and advice.</b></p>
            </div>
            <div class="container mt-4 d-flex justify-content-center ">
                <div class="row g-0">
                    <div class="col-md-3 border-right">
                        <div class="cards">
                            <div class="first  p-4 text-center">
                                <img src={img4} />

                                <h5>Recognized by Top Universities</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 border-right">
                        <div class="cards">
                            <div class=" second  p-4 text-center">
                                <img src={img3} />

                                <h5>Strong And Well Educated Team</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="cards">
                            <div class=" third  p-4 text-center">
                                <img src={img2} />
                                <h5>Experienced Student Consultant</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="cards">
                            <div class=" third  p-4 text-center">
                                <img src={img1} />
                                <h5>Help Students For Scholarships</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyBest;