import React from 'react';
import HeaderNav from '../HeaderNav/HeaderNav';
import Footer from '../Footer/Footer';
import bg from '../../assets/blogBg.jpg'

const ThankYou = () => {
    
    return (
        <div>
            <HeaderNav />
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">

                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Thanks For Registration <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    );
};

export default ThankYou;