import React from 'react';
import { Accordion } from 'react-bootstrap';
import './OfficeLocation.css'

const OfficeLocation = () => {
    return (
        <div className='container-fluid officeBg'>
            <h1 className='text-center officeHeading'>Our Offices</h1>
            <div className="container ">
                <div className='row  mt-5 '>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header ><b>Head Office (London)</b></Accordion.Header>
                                <Accordion.Body>
                                    1st Floor, 94A Whitechapel High Street
                                    London
                                    E1 7RA
                                    United Kingdom.
                                    Tel: (+44) 7404016346 <br />
                                    E-mail: info@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>South Asian Regional Head Office (Dhaka)</b></Accordion.Header>
                                <Accordion.Body>
                                    Nagar Lutfun Homes
                                    House No.11
                                    Road No. 17, Block D
                                    Banani
                                    Dhaka
                                    Bangladesh

                                    Tel: (+880) 1321182535
                                    Email: applications.bd@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Sylhet Office</b></Accordion.Header>
                                <Accordion.Body>
                                    3rd Floor, Symphony Heights
                                    Baruth Khana
                                    East Zindabazar
                                    Sylhet
                                    Bangladesh

                                    Tel: (+880) 17 0180 8655
                                    Email: om.bd@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>Vietnam office</b></Accordion.Header>
                                <Accordion.Body>
                                    <p></p>

                                    <p></p>
                                    <p>Email: info@shabujglobal.com</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>Nigeria office (Lagos) </b></Accordion.Header>
                                <Accordion.Body>
                                    <p>57, Bode Thomas Street, Surulere <br />Tel: 09065327323, 07048293093 <br />Email: africa@shabujglobal.com, <br /><small>applications.nigeria@shabujglobal.com</small></p>
                                    <p></p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Nigeria office (Abuja) </b></Accordion.Header>
                                <Accordion.Body>
                                    <p>No. 9 Senangal Crescent, Wuse Zone 5, Abuja, Nigeria <br />Tel: +2349033173017<br />Email: africa@shabujglobal.com</p>
                                    <p></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>India Office (kannur)</b>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Daliya Archade,
                                    4 th floor, NCC Road, Thalassery, Pilakool, Thalassery, Kerala 670101, India

                                    Tel: 00918129255564
                                    Email: india@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>India Office (Kochin)</b>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Shabuj Global Kochin <br />
                                    Door Number: 303,<br />
                                    3rd Floor, Cityscape,<br />
                                    South Kalamasery,<br />
                                    Kochi-Kerala, 682033 <br />
                                    Email: india@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Khulna Office</b></Accordion.Header>
                                <Accordion.Body>
                                    Tel: (+880) 18 3337 6727
                                    Email: unidirect2@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>Chattogram Office</b></Accordion.Header>
                                <Accordion.Body>
                                    R.I. Tower (level 3) <br />
                                    23/A MM Ali Road <br />Golpahar moor,
                                    Chattogram, Bangladesh.
                                    <br />
                                    Tel: (880)1321182544
                                    <br />Email: applications.ctg@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>Nepal Office</b></Accordion.Header>
                                <Accordion.Body>
                                    <p>3rd Floor, Putalisadak, रामशाह पथ, Kathmandu 44600</p>
                                    <p>Whatsapp: 00447903108549</p>
                                    <p><small>Email: applications.nepal@shabujglobal.com</small></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Dhanmondi Office</b></Accordion.Header>
                                <Accordion.Body>
                                    <p>759, Delvista Fuljhuri(Lift-5) <br />Satmosjid Road, Dhanmondi, Dhaka-1207</p>
                                    <p> 01701808655, 01321182535</p>
                                    <p><small>Email: om.bd@shabujglobal.com</small></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default OfficeLocation;



/*

london
nigeria lagos
nigeria abuja

dhaka sylhet khulna ctg

vietnam, india, nepal




























*/



















/*

original code


        <div className='container-fluid officeBg'>
            <h1 className='text-center officeHeading'>Our Offices</h1>
            <div className="container ">
                <div className='row  mt-5 '>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header ><b>Head Office (London)</b></Accordion.Header>
                                <Accordion.Body>
                                    1st Floor, 94A Whitechapel High Street
                                    London
                                    E1 7RA
                                    United Kingdom.
                                    Tel: (+44) 7404016346 <br />
                                    E-mail: info@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>South Asian Regional Head Office (Dhaka)</b></Accordion.Header>
                                <Accordion.Body>
                                    Nagar Lutfun Homes
                                    House No.11
                                    Road No. 17, Block D
                                    Banani
                                    Dhaka
                                    Bangladesh

                                    Tel: (+880) 1321182535
                                    Email: applications.bd@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Sylhet Office</b></Accordion.Header>
                                <Accordion.Body>
                                    3rd Floor, Symphony Heights
                                    Baruth Khana
                                    East Zindabazar
                                    Sylhet
                                    Bangladesh

                                    Tel: (+880) 17 0180 8655
                                    Email: om.bd@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>Vietnam office</b></Accordion.Header>
                                <Accordion.Body>
                                    <p></p>

                                    <p></p>
                                    <p>Email: info@shabujglobal.com</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>Nigeria office (Lagos) </b></Accordion.Header>
                                <Accordion.Body>
                                    <p>57, Bode Thomas Street, Surulere <br />Tel: 09065327323, 07048293093 <br />Email: africa@shabujglobal.com, <br /><small>applications.nigeria@shabujglobal.com</small></p>
                                    <p></p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><b>Nigeria office (Abuja) </b></Accordion.Header>
                                <Accordion.Body>
                                    <p>No. 9 Senangal Crescent, Wuse Zone 5, Abuja, Nigeria <br />Tel: +2349033173017<br />Email: africa@shabujglobal.com</p>
                                    <p></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>India Office</b>
                                </Accordion.Header>
                                <Accordion.Body>
                                    Daliya Archade,
                                    4 th floor, NCC Road, Thalassery, Pilakool, Thalassery, Kerala 670101, India

                                    Tel: 00918129255564
                                    Email: india@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>Khulna Office</b></Accordion.Header>
                                <Accordion.Body>
                                    Tel: (+880) 18 3337 6727
                                    Email: unidirect2@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="col-md-3 pb-3">
                        <Accordion  >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>Chattogram Office</b></Accordion.Header>
                                <Accordion.Body>
                                    R.I. Tower (level 3) <br />
                                    23/A MM Ali Road <br />Golpahar moor,
                                    Chattogram, Bangladesh.
                                    <br />
                                    Tel: (880)1321182544
                                    <br />Email: applications.ctg@shabujglobal.com
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>Nepal Office</b></Accordion.Header>
                                <Accordion.Body>
                                    <p>3rd Floor, Putalisadak, रामशाह पथ, Kathmandu 44600</p>
                                    <p>Whatsapp: 00447903108549</p>
                                    <p><small>Email: applications.nepal@shabujglobal.com</small></p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                </div>
            </div>
        </div>




*/