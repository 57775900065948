import React, { useEffect, useState } from 'react';

const Registration4 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    }

    return (
        <div>
            <div>


                {/* CRM link */}
                <div style={{ position: 'relative', width: '100%', height: '1500px', paddingBottom: '56.25%' }}>
                    {loading && (
                        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
                            <p>Loading.....</p>
                        </div>
                    )}
                    <iframe
                        src="https://app.shabujglobal.com/registration4"
                        scrolling="no"
                        style={{ position: 'absolute', width: '100%', height: '1500px', left: 0, top: 0 }}
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        onLoad={handleLoad}

                    >
                        Loading…
                    </iframe>
                </div>
            </div>
        </div>
    );
};

export default Registration4;