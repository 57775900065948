import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
import career from '../../assets/aboutMe1.png'
import { Link } from 'react-router-dom';
import CareerCards from '../CareerCards/CareerCards';
const Career = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div>
                <HeaderNav></HeaderNav>
                <div class="container-fluid mt-5 bg-light bg-gradient shadow ">
                    <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                        backgroundImage: `url(${bg})`,
                    }}>
                        <div class="col-md-12 px-0">
                            <h1 class="pt-5 display-4 font-italic text-center"> Digital Marketing Executive <span class="text-success"></span></h1>
                        </div>
                    </div>
                </div>


                {/* <h3 className='text-center'>Job Openings</h3>
                <h4 className="text-center">currently no jobs available</h4> */}


                <main class="container bg-light py-4">
                    <div class="row">
                        <aside class="col-md-4">
                            <div class="p-4 mb-3 bg-primary text-white rounded shadow-sm">
                                <h4 class="font-italic">Exclusive <span class="badge rounded-pill bg-warning">News!!!</span></h4>
                                <p class="mb-0">
                                    The CEO of Shabuj Global Education made a memorable appearance at <b>Oxford University</b>'s Arab Society's 1001 Nights Ball. This event celebrated the rich cultures of the Middle East and North Africa, emphasizing the importance of diversity. Visit our Facebook page to learn more...</p>
                            </div>


                        </aside>
                        <div class="col-md-8 px-4">
                            <div class="blog-post">
                                <h2 class="blog-post-title">Job Title: Digital Marketing Executive</h2>
                                <p class="blog-post-meta">May 26, 2023</p>
                                {/* <p className='text-danger'><b>Not all free consultations are SCAM!</b> </p> */}
                                <h1>Job Description:</h1>
                                <p>We are seeking a skilled and experienced Digital Marketing Executive to join our team. As a Digital Marketing Executive, you will be responsible for managing and optimizing advertising campaigns on Facebook and Google platforms. You will work closely with our marketing team to drive brand awareness, increase website traffic, and generate leads through effective online advertising strategies.</p>

                                <h2>Responsibilities:</h2>
                                <ul>
                                    <li>Develop and implement digital marketing campaigns across Facebook and Google platforms.</li>
                                    <li>Conduct keyword research and optimize ad copy to maximize performance.</li>
                                    <li>Monitor campaign performance, analyze data, and make data-driven decisions to optimize campaigns for better ROI.</li>
                                    <li>A/B test ad creatives, landing pages, and targeting parameters to improve campaign effectiveness.</li>
                                    <li>Stay updated with the latest trends and best practices in digital advertising.</li>
                                    <li>Collaborate with the marketing team to align digital advertising strategies with overall marketing objectives.</li>
                                    <li>Track and report on key performance metrics and provide regular campaign performance insights and recommendations.</li>
                                </ul>

                                <h2>Requirements:</h2>
                                <ul>
                                    {/* <li>Bachelor's degree in Marketing, Advertising, or a related field.</li> */}
                                    <li>Proven experience in managing Facebook Ads and Google Ads campaigns.</li>
                                    <li>Strong understanding of digital marketing principles and best practices.</li>
                                    <li>Proficient in using Facebook Ads Manager and Google Ads platforms.</li>
                                    <li>Familiarity with audience targeting, ad formats, and bidding strategies.</li>
                                    <li>Excellent analytical skills and ability to interpret data to drive campaign optimizations.</li>
                                    <li>Strong written and verbal communication skills.</li>
                                    <li>Ability to work in a fast-paced environment and meet deadlines.</li>
                                    <li>Certifications in Facebook Blueprint and Google Ads are a plus.</li>
                                </ul>

                                <p>If you are passionate about digital marketing, have hands-on experience with Facebook and Google Ads, and are eager to contribute to the success of our company's online advertising efforts, we would love to hear from you.</p>

                                <p>Please submit your resume, portfolio, and a brief summary of your experience managing digital advertising campaigns to <a href="mailto:hrm@hsedu.co.uk">hrm@hsedu.co.uk</a>.</p>
                                <p>Note: When emailing your CV to us, must include "<b>DME SGEdu 2023</b>" in the subject line.</p>

                                {/* <p>Note: This job description is just an example and can be customized based on the specific requirements and preferences of your company.</p> */}

                            </div>
                        </div>
                    </div>
                </main>

                <CareerCards/>

                <Footer></Footer>
            </div>
        </div>
    );
};

export default Career;