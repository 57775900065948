import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';

import img1 from '../../assets/PhotoEvent/dhaka23/DSC06774.jpg'
import img2 from '../../assets/PhotoEvent/dhaka23/DSC06428.jpg'
import img3 from '../../assets/PhotoEvent/dhaka23/DSC06439.jpg'
import img4 from '../../assets/PhotoEvent/dhaka23/DSC06448.jpg'
import img5 from '../../assets/PhotoEvent/dhaka23/DSC06474.jpg'
import img6 from '../../assets/PhotoEvent/dhaka23/DSC06481.jpg'
import img7 from '../../assets/PhotoEvent/dhaka23/DSC06484.jpg'
import img8 from '../../assets/PhotoEvent/dhaka23/DSC06612.jpg'
// openday university Bedfordshire
import img9 from '../../assets/PhotoEvent/OpenDayUniversityBedfordshire/img1.jpg'
import img10 from '../../assets/PhotoEvent/OpenDayUniversityBedfordshire/img2.jpg'
import img11 from '../../assets/PhotoEvent/OpenDayUniversityBedfordshire/img3.jpg'
// university of birmiongham
import img12 from '../../assets/PhotoEvent/QueensBirminghamDundee/bir1.jpg'
import img13 from '../../assets/PhotoEvent/QueensBirminghamDundee/bir2.jpg'
import img14 from '../../assets/PhotoEvent/QueensBirminghamDundee/bir3.jpg'
import img15 from '../../assets/PhotoEvent/QueensBirminghamDundee/bir4.jpg'
// university of Dundee
import img16 from '../../assets/PhotoEvent/QueensBirminghamDundee/dun1.jpg'
import img17 from '../../assets/PhotoEvent/QueensBirminghamDundee/dun2.jpg'
import img18 from '../../assets/PhotoEvent/QueensBirminghamDundee/dun3.jpg'
import img19 from '../../assets/PhotoEvent/QueensBirminghamDundee/dun4.jpg'
// university of Queen uni belfast
import img20 from '../../assets/PhotoEvent/QueensBirminghamDundee/qub1.jpg'
import img21 from '../../assets/PhotoEvent/QueensBirminghamDundee/qub2.jpg'
import img22 from '../../assets/PhotoEvent/QueensBirminghamDundee/qub3.jpg'
import img23 from '../../assets/PhotoEvent/QueensBirminghamDundee/qub4.jpg'


import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GalleryFull = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeaderNav></HeaderNav>
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">

                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Photo Gallery <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>


            {/* Spot Assessment session University of Queens university belfast */}
            <section className="container">
                <h1 className="my-4 text-center text-lg-left galleryHeading">Spot Assessment Session Queen's University Belfast</h1>
                <h6 className='text-center' style={{ fontWeight: '600' }}>
                    Afshana Jafri, Student Recruitment Manager from Queen's University Belfast, visited our Dhaka office and provided intensive training to our highly skilled counselors. She also shared valuable information for those interested in studying at Queen's University Belfast. Visit our <Link to='/contact'>Dhaka office</Link> to learn more about these opportunities.
                </h6>


                <div className="row gallery">


                <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal211">
                        <figure><img className="img-fluid img-thumbnail" src={img20} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal211" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img20} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal231">
                        <figure><img className="img-fluid img-thumbnail" src={img22} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal231" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img22} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal241">
                        <figure><img className="img-fluid img-thumbnail" src={img23} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal241" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img23} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal221">
                        <figure><img className="img-fluid img-thumbnail" src={img21} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal221" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img21} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
            {/* Spot Assessment session University of Birmingham */}
            <section className="container">
                <h1 className="my-4 text-center text-lg-left galleryHeading">Spot Assessment Session University of Birmingham</h1>
                <h6 className='text-center' style={{ fontWeight: '600' }}>
                Niharika Gupta, the esteemed Country Officer from the University of Birmingham, conducted an enriching training session for our proficient counselors. With her expertise, she not only empowered our team but also shared invaluable insights for aspiring students considering the University of Birmingham. We invite you to explore these exciting opportunities further by visiting our <Link to='/contact'>Dhaka office</Link>. Your journey towards academic excellence begins here!
                </h6>

                <div className="row gallery">

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal21">
                        <figure><img className="img-fluid img-thumbnail" src={img14} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal21" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img14} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal22">
                        <figure><img className="img-fluid img-thumbnail" src={img12} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal22" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img12} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal23">
                        <figure><img className="img-fluid img-thumbnail" src={img13} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal23" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img13} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal24">
                        <figure><img className="img-fluid img-thumbnail" src={img15} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal24" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img15} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* Spot Assessment session University of Dundee */}
            <section className="container">
                <h1 className="my-4 text-center text-lg-left galleryHeading">Spot Assessment Session University of Dundee</h1>
                <h6 className='text-center' style={{ fontWeight: '600' }}>
                In a recent enlightening session at our <Link to='/contact'>Dhaka office</Link>, Jonathan Fortune, the esteemed International Officer from the University of Dundee, led a captivating training session for our skilled counselors. His profound expertise not only empowered our team but also illuminated the path for prospective students interested in the University of Dundee. We extend a warm invitation to you to delve deeper into these captivating prospects by visiting our <Link to='/contact'>Dhaka office</Link>. Your academic journey towards excellence commences right here, with us!
                </h6>
                <div className="row gallery">

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal99">
                        <figure><img className="img-fluid img-thumbnail" src={img16} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal99" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img16} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal98">
                        <figure><img className="img-fluid img-thumbnail" src={img17} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal98" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img17} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal97">
                        <figure><img className="img-fluid img-thumbnail" src={img19} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal97" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img19} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal96">
                        <figure><img className="img-fluid img-thumbnail" src={img18} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal96" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img18} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>




            {/* openday university Bedfordshire */}
            <section className="container">
                <h1 className="my-4 text-center text-lg-left galleryHeading">Openday university of Bedfordshire</h1>

                <div className="row gallery">
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal9">
                        <figure><img className="img-fluid img-thumbnail" src={img9} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img9} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal10">
                        <figure><img className="img-fluid img-thumbnail" src={img10} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal10" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img10} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal11">
                        <figure><img className="img-fluid img-thumbnail" src={img11} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img11} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Dhaka event */}
            <section className="container">
                <h1 className="my-4 text-center text-lg-left galleryHeading">Dhaka Event 2023</h1>
                <div className="row gallery">
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                        <figure><img className="img-fluid img-thumbnail" src={img1} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                        <figure><img className="img-fluid img-thumbnail" src={img2} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <Carousel>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={img1}
                                                alt="First slide"
                                            />
                                            <Carousel.Caption>
                                                <h3></h3>
                                                <p></p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={img2}
                                                alt="Second slide"
                                            />
                                            <Carousel.Caption>
                                                <h3></h3>
                                                <p></p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={img3}
                                                alt="Third slide"
                                            />

                                            <Carousel.Caption>
                                                <h3></h3>
                                                <p></p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                        <figure><img className="img-fluid img-thumbnail" src={img3} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                        <figure><img className="img-fluid img-thumbnail" src={img4} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img4} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal5">
                        <figure><img className="img-fluid img-thumbnail" src={img5} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal6">
                        <figure><img className="img-fluid img-thumbnail" src={img6} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img6} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal7">
                        <figure><img className="img-fluid img-thumbnail" src={img7} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal7" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img7} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 thumb" data-bs-toggle="modal" data-bs-target="#exampleModal8">
                        <figure><img className="img-fluid img-thumbnail" src={img8} alt="Random Image" /></figure>
                    </div>
                    <div className="modal fade" id="exampleModal8" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="text-center fs-5 " id="exampleModalLabel"></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <p className='text-center'></p>
                                <div className="modal-body text-center w-100">
                                    <img className='w-100' src={img8} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer></Footer>
        </div>
    );
};

export default GalleryFull;