import React from 'react';
import './VideoHeader.css'
import vidBg2 from '../../assets/vidBg.mp4'
import { ImFacebook } from 'react-icons/im';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';
// import { AiOutlineTwitter } from 'react-icons/ai';
import { RiTwitterXLine } from "react-icons/ri";
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom';

const VideoHeader = () => {
    return (
        <div>
            <section class="showcase">
                <video src={vidBg2} muted loop autoPlay />
                <div class="overlay"></div>
                <div class="text">
                    <h1>Start Your </h1>
                    <h3>Path to Global Education</h3>
                    <p>We take pride in our ability to help students achieve their academic goals and succeed in life.</p>

                    <a href="https://www.shabujglobal.com/registration/"  rel="noreferrer">
                    <button   className='glow-on-hover vidHeaderBtn'>Apply Now</button></a>

                    {/* <NavLink to='/registration'
                        style={{
                            color: 'white',
                            className: 'glow-on-hover'
                        }}>Apply Now</NavLink> */}



                </div>
                <ul class="social">
                    <li><a href="https://www.facebook.com/ShabujGlobaleducationuk/" target="_blank" rel="noreferrer"><ImFacebook style={{ fontSize: '46px', color: '#261919' }} /></a></li>
                    <li><a href="https://twitter.com/shabujglobaluk" target="_blank" rel="noreferrer"><RiTwitterXLine style={{ fontSize: '46px', color: '#261919' }} /></a></li>
                    <li><a href="https://www.linkedin.com/company/shabuj-global-education/" target="_blank" rel="noreferrer"><AiOutlineLinkedin style={{ fontSize: '46px', color: '#261919' }} /></a></li>
                    <li><a href="https://youtube.com/@shabujglobaleducation1568" target="_blank" rel="noreferrer"><AiFillYoutube style={{ fontSize: '46px', color: '#261919' }} /></a></li>
                    <li><a href="https://instagram.com/shabujglobaleducation" target="_blank" rel="noreferrer"><AiOutlineInstagram style={{ fontSize: '46px', color: '#261919' }} /></a></li>
                </ul>
            </section>
        </div>
    );
};

export default VideoHeader;