import React, { useEffect } from 'react';
import BlogBg from '../../assets/blogBg.jpg'

import './AboutUs.css'
import WhyBest from '../../Shared/WhyBest/WhyBest';
import CommonContactUsSec from '../../Shared/CommonContactUsSec/CommonContactUsSec';
const AboutUs = () => {
      useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    return (
        <div>
            <div class="container-fluid bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${BlogBg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="display-4 font-italic text-center">About <span class="text-success"></span> Us</h1>
                    </div>
                </div>
            </div>
            <div className='container'>     
                <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>An Excellent Solution for Your Admission</h1>
                </div>
                <p className='slide-from-right-to-left'>
                    Shabuj Global Education (also known as SG Education) is one of the BRITISH COUNCIL accredited education service providers in the UK. The company has been working since 2010 with great pride and service excellence. At Shabuj Global we provide services to local and international students for UK University admission. Our dedicated team with years of experience help our clients with highest professional standards.
                </p>
                <div class="container">
                    <div class="row">
                        <div class="card col-md-12 p-3">
                            <div class="row ">
                                <div class="col-md-4">
                                    <iframe className='container h-100' src="https://www.youtube.com/embed/TM7M0LIu1to" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                                <div class="col-md-8">
                                    <div class="card-block">
                                        <h2 class="card-title text-center">Our Goal and Mission</h2>
                                        <p class="card-text text-justify">At Shabuj Global, we build bridges between our students and the many Institutes of higher education in the UK. We take time to get to know our student customers, and it is this personal touch that helps us to find the correct course for them. At Shabuj Global we hope to help students make the right choices, and then we help them bring those choices to realisation. The end goal is a satisfactory and complete education and a student ready to face the world with confidence.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid myServiceBg slide-from-left-to-right'>
                    <h1 className='text-center text-white'>Overview</h1>
                </div>
                <p className='slide-from-left-to-right'>
                    Shabuj Global Education is an enlisted professional student consultancy firm, approved by the British Council. It specialises in academic counselling, course placement, and internal student recruitment. It has been working with universities from around the world to find the right UK course placements since 2010. Shabuj Global helps its customers find <b>undergraduate, postgraduate, and foundation courses,</b> and also aids MBA and MSc students.
                </p>
                <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>Core Competencies</h1>
                </div>
                <p className='slide-from-right-to-left'>
                    Shabuj Global Education has already helped over 1,45,000+ students find admission to the right course for them. Sometimes, this has meant transferring from one university to another, and we helped them with that as well! Our dedicated administrative team works closely not only with our customers but also with the universities and colleges that provide the all-important courses.
                </p>
            </div>
            <WhyBest></WhyBest>
            <CommonContactUsSec />
        </div>
    );
};

export default AboutUs;