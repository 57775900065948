import React, { useEffect } from 'react';
import bg from '../../assets/blogBg.jpg'
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
const Agents = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <HeaderNav></HeaderNav>
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Channel Partner <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>Join Our Team of Dedicated Channel Partner at SGE</h1>
                </div>
                <p>At <b>SGE</b>, we are committed to delivering top-quality services to our clients and being leaders in our market sectors. To achieve this goal, we are always looking for dedicated and enthusiastic Channel Partner to join our team.</p>
                <div className="text-center m-1">
                    <a href="https://cpi.shabujglobal.com/" target='_blank' rel="noreferrer">
                        <button className='btn btn-primary'>Join Us</button>  </a>
                </div>
                <div className='container-fluid myServiceBg slide-from-left-to-right'>
                    <h1 className='text-center text-white'>How Our Channel Partner Drive Our Success</h1>
                </div>
                <p>Our Channel Partners are like-minded individuals who share our passion for excellence and have a genuine interest in the work that we do. Whether it's customer service, sales, or any other area of expertise, we believe that our success is driven by the quality of our team.</p>
                <div className="text-center m-1">
                    <a href="https://cpi.shabujglobal.com/" target='_blank' rel="noreferrer">
                        <button className='btn btn-primary'>Join Us</button>  </a>
                </div>
                <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>Join our team of committed professionals at SGE</h1>
                </div>
                <p>If you're someone who shares our values and wants to work with a nice bunch of people who are committed to delivering the best results, we would love to hear from you. We offer a supportive and collaborative work environment where you can learn, grow, and achieve your professional goals.</p>
                <p>At <b>SGE</b>, we value our Channel Partner and invest in their development. We provide ongoing training and support to ensure that our Channel Partner are equipped with the skills and knowledge they need to excel in their roles. We also offer competitive compensation packages and opportunities for career advancement.</p>
                <p>Join our team today and become part of a quality-focused and enthusiastic group of professionals who are dedicated to delivering exceptional results to our clients. Apply now and let's work together to make a difference in the world of Education Sector</p>
                <div className="text-center m-1">
                    <a href="https://cpi.shabujglobal.com/" target='_blank' rel="noreferrer">
                        <button className='btn btn-primary'>Join Us</button>  </a>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Agents;