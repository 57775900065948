import React from 'react';
import Marquee from "react-fast-marquee";



import { Container } from 'react-bootstrap';
import './UniLogoSlider.css'




import i1 from '../../assets/Uni Logo update/1.png'
import i2 from '../../assets/Uni Logo update/2.png'
import i3 from '../../assets/Uni Logo update/3.png'
import i4 from '../../assets/Uni Logo update/4.png'
import i5 from '../../assets/Uni Logo update/5.png'
import i6 from '../../assets/Uni Logo update/6.png'
import i7 from '../../assets/Uni Logo update/7.png'
import i8 from '../../assets/Uni Logo update/8.png'
import i9 from '../../assets/Uni Logo update/9.png'
import i10 from '../../assets/Uni Logo update/10.png'
import i11 from '../../assets/Uni Logo update/11.png'
// import i12 from '../../assets/Uni Logo update/12.png'
import i13 from '../../assets/Uni Logo update/13.png'
import i14 from '../../assets/Uni Logo update/14.png'
import i15 from '../../assets/Uni Logo update/15.png'
import i16 from '../../assets/Uni Logo update/16.png'
// import i17 from '../../assets/Uni Logo update/17.png'
import i18 from '../../assets/Uni Logo update/18.png'

import i19 from '../../assets/Uni Logo update/19u.png'
import i20 from '../../assets/Uni Logo update/20.png'
import i21 from '../../assets/Uni Logo update/21.png'
import i22 from '../../assets/Uni Logo update/22.png'
import i23 from '../../assets/Uni Logo update/23.png'
import i24 from '../../assets/Uni Logo update/24.png'
import i25 from '../../assets/Uni Logo update/25.png'
import i26 from '../../assets/Uni Logo update/26.png'
import i27 from '../../assets/Uni Logo update/27.png'
import i28 from '../../assets/Uni Logo update/28.png'
import i29 from '../../assets/Uni Logo update/29.png'
import i30 from '../../assets/Uni Logo update/30.png'
import i31 from '../../assets/Uni Logo update/31.png'
import i32 from '../../assets/Uni Logo update/32.png'
// import i33 from '../../assets/Uni Logo update/33.png'
import i34 from '../../assets/Uni Logo update/34.png'
import i35 from '../../assets/Uni Logo update/35.png'
import i36 from '../../assets/Uni Logo update/36.png'
import i37 from '../../assets/Uni Logo update/37.png'
import i38 from '../../assets/Uni Logo update/38.png'
import i39 from '../../assets/Uni Logo update/39.png'
import i40 from '../../assets/Uni Logo update/40.png'
import i41 from '../../assets/Uni Logo update/41.png'
import i42 from '../../assets/Uni Logo update/42.png'
import i43 from '../../assets/Uni Logo update/43.png'
import i44 from '../../assets/Uni Logo update/44.png'
import i45 from '../../assets/Uni Logo update/45.png'
import i46 from '../../assets/Uni Logo update/46.png'
import i47 from '../../assets/Uni Logo update/47.png'
import i48 from '../../assets/Uni Logo update/25.png'
import i49 from '../../assets/Uni Logo update/48.png'
import i50 from '../../assets/Uni Logo update/49.png'
import i51 from '../../assets/Uni Logo update/50.png'
import i52 from '../../assets/Uni Logo update/51.png'
import i53 from '../../assets/Uni Logo update/52.png'
import { Link } from 'react-router-dom';





const UniLogoSlider = () => {
    return (
        <Container fluid>
            <Link to='/Universities'>
                <div className=''>
                    <h1 className='text-center pt-2 pb-2 uniHeading'>Our Partners</h1>
                    <Marquee className='sliderNoScroll' pauseOnHover={true} speed={100}>
                        <div className='mx-1'>
                            <img src={i1} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i2} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i3} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i4} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i5} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i6} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i7} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i8} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i9} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i10} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i11} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i18} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i13} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i14} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i15} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i16} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i49} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i51} alt="img" />
                        </div>
                        {/* <div className='mx-1'>
                            <img src={i18} alt="img" />
                        </div> */}
                        <div className='mx-1'>
                            <img src={i19} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i20} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i21} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i22} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i23} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i24} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i25} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i26} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i27} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i28} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i29} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i30} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i31} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i32} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i50} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i52} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i34} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i35} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i36} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i37} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i38} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i39} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i40} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i41} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i53} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i42} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i43} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i44} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i45} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i46} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i47} alt="img" />
                        </div>
                        <div className='mx-1'>
                            <img src={i48} alt="img" />
                        </div>
                
                    </Marquee>
                </div>
            </Link>
        </Container>
    );
};

export default UniLogoSlider;