import React from 'react';
import Marquee from 'react-fast-marquee';
import './FacebookReview.css'

const FacebookReview = () => {
    return (
        <div >
            <Marquee pauseOnHover direction='right' className='sliderNoScroll'>
                <div className='p-4'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpurba.tulshi%2Fposts%2Fpfbid031GWRHZAUhDS7YteGimh1ZFVFiZcHTZ9ua4j2ePxuEsUPHf3xfRbuqa4aaWNjqy4Kl&show_text=true&width=500" width="500" height="188" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className='p-4'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsuhel.miah.7982%2Fposts%2Fpfbid02jFTVmTdj4AUYY3WKeUuAtiwhCqKJMbzuKSiYAr6WWPVsJF6HuGTj3Zod64d5wL4jl&show_text=true&width=500" width="500" height="169" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className='p-4'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fh.s.hridoy%2Fposts%2Fpfbid02GKCzBXnnZovhE5rMhuikWGQQHmTciRnTm9dUkBck6fQkBf75Dw7MQvPj81h3sh5el&show_text=true&width=500" width="500" height="285" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className='p-4'>
                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02YPKEz4p8N3oXAHE1oD6ir1UC72QLhRQSamPegmUH4vKeKwwdezFg33fgC9sLTQcNl%26id%3D100005368124666&show_text=true&width=500" width="500" height="227" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
   
                <div className='p-4'>
                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbokther.ahmed.7%2Fposts%2Fpfbid02LbfkmJc5Q21vbKgykzGe2QToE9CQ4oGZV24Ge1U5paejbpUZTbYGUh4LVZDCvUnNl&show_text=true&width=500" width="500" height="265" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
            </Marquee>
        </div>
    );
};

export default FacebookReview;

