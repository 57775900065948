import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
import BlogBg from '../../assets/blogBg.jpg'


import i1 from '../../assets/Uni Logo update/1.png'
import i2 from '../../assets/Uni Logo update/2.png'
import i3 from '../../assets/Uni Logo update/3.png'
import i4 from '../../assets/Uni Logo update/4.png'
import i5 from '../../assets/Uni Logo update/5.png'
import i6 from '../../assets/Uni Logo update/6.png'
import i7 from '../../assets/Uni Logo update/7.png'
import i8 from '../../assets/Uni Logo update/8.png'
import i9 from '../../assets/Uni Logo update/9.png'
import i10 from '../../assets/Uni Logo update/10.png'
import i11 from '../../assets/Uni Logo update/11.png'
// import i12 from '../../assets/Uni Logo update/12.png'
import i13 from '../../assets/Uni Logo update/13.png'
import i14 from '../../assets/Uni Logo update/14.png'
import i15 from '../../assets/Uni Logo update/15.png'
import i16 from '../../assets/Uni Logo update/16.png'
// import i17 from '../../assets/Uni Logo update/17.png'
import i18 from '../../assets/Uni Logo update/18.png'
import i19 from '../../assets/Uni Logo update/19.png'
import i20 from '../../assets/Uni Logo update/20.png'
import i21 from '../../assets/Uni Logo update/21.png'
import i22 from '../../assets/Uni Logo update/22.png'
import i23 from '../../assets/Uni Logo update/23.png'
import i24 from '../../assets/Uni Logo update/24.png'
import i25 from '../../assets/Uni Logo update/25.png'
import i26 from '../../assets/Uni Logo update/26.png'
import i27 from '../../assets/Uni Logo update/27.png'
import i28 from '../../assets/Uni Logo update/28.png'
import i29 from '../../assets/Uni Logo update/29.png'
import i30 from '../../assets/Uni Logo update/30.png'
import i31 from '../../assets/Uni Logo update/31.png'
import i32 from '../../assets/Uni Logo update/32.png'
// import i33 from '../../assets/Uni Logo update/33.png'
import i34 from '../../assets/Uni Logo update/34.png'
import i35 from '../../assets/Uni Logo update/35.png'
import i36 from '../../assets/Uni Logo update/36.png'
import i37 from '../../assets/Uni Logo update/37.png'
import i38 from '../../assets/Uni Logo update/38.png'
import i39 from '../../assets/Uni Logo update/39.png'
import i40 from '../../assets/Uni Logo update/40.png'
import i41 from '../../assets/Uni Logo update/41.png'
import i42 from '../../assets/Uni Logo update/42.png'
import i43 from '../../assets/Uni Logo update/43.png'
import i44 from '../../assets/Uni Logo update/44.png'
import i45 from '../../assets/Uni Logo update/45.png'
import i46 from '../../assets/Uni Logo update/46.png'
import i47 from '../../assets/Uni Logo update/47.png'
import i48 from '../../assets/Uni Logo update/25U.png'
import i49 from '../../assets/Uni Logo update/48.png'
import i50 from '../../assets/Uni Logo update/49.png'
// solent, cantebery, robert
import i51 from '../../assets/Uni Logo update/50.png'
import i52 from '../../assets/Uni Logo update/51.png'
import i53 from '../../assets/Uni Logo update/52.png'
import s1 from '../../assets/Uni Logo update/studyGroup/s1.svg'
import s2 from '../../assets/Uni Logo update/studyGroup/s2.webp'
import s3 from '../../assets/Uni Logo update/studyGroup/s3.webp'
import s4 from '../../assets/Uni Logo update/studyGroup/s4.svg'
import s5 from '../../assets/Uni Logo update/studyGroup/s5.svg'
import s6 from '../../assets/Uni Logo update/studyGroup/s6.svg'
import s7 from '../../assets/Uni Logo update/studyGroup/s7.svg'
import s8 from '../../assets/Uni Logo update/studyGroup/s8.svg'
import s9 from '../../assets/Uni Logo update/studyGroup/9.svg'
import s10 from '../../assets/Uni Logo update/studyGroup/10.svg'
import s11 from '../../assets/Uni Logo update/studyGroup/11.svg'
import s12 from '../../assets/Uni Logo update/studyGroup/12.svg'
import s13 from '../../assets/Uni Logo update/studyGroup/13.svg'
import s14 from '../../assets/Uni Logo update/studyGroup/14.svg'
import s15 from '../../assets/Uni Logo update/studyGroup/15.svg'
import s16 from '../../assets/Uni Logo update/studyGroup/16.svg'
import s17 from '../../assets/Uni Logo update/studyGroup/17.webp'
import s18 from '../../assets/Uni Logo update/studyGroup/18.svg'
import s19 from '../../assets/Uni Logo update/studyGroup/19.svg'
import s20 from '../../assets/Uni Logo update/studyGroup/20.svg'
import s21 from '../../assets/Uni Logo update/studyGroup/21.webp'
import s22 from '../../assets/Uni Logo update/studyGroup/22.svg'
import s23 from '../../assets/Uni Logo update/studyGroup/23.svg'
import s24 from '../../assets/Uni Logo update/studyGroup/24.svg'
import s25 from '../../assets/Uni Logo update/studyGroup/25.svg'
import s26 from '../../assets/Uni Logo update/studyGroup/26.svg'
import s27 from '../../assets/Uni Logo update/studyGroup/27.svg'
import s28 from '../../assets/Uni Logo update/studyGroup/28.svg'
import s29 from '../../assets/Uni Logo update/studyGroup/29.svg'
import s30 from '../../assets/Uni Logo update/studyGroup/30.svg'
// navitas
import n1 from '../../assets/Uni Logo update/navitas/1.jpg'
import n2 from '../../assets/Uni Logo update/navitas/2.jpg'
import n3 from '../../assets/Uni Logo update/navitas/3.jpg'
import n4 from '../../assets/Uni Logo update/navitas/4.jpg'
import n5 from '../../assets/Uni Logo update/navitas/5.jpg'
// qa
import q1 from '../../assets/Uni Logo update/QA/qa1.png'
import q2 from '../../assets/Uni Logo update/QA/qa2.jpg'
import q3 from '../../assets/Uni Logo update/QA/qa5.png'
import q4 from '../../assets/Uni Logo update/QA/qa3.jpg'
import q5 from '../../assets/Uni Logo update/QA/qa7.png'
import q6 from '../../assets/Uni Logo update/QA/qa4.png'
import q7 from '../../assets/Uni Logo update/delete/25.jpg'


import { Accordion, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Universities = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className='mt-2'>
            <HeaderNav></HeaderNav>
            <div class="mt-5 container-fluid bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${BlogBg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Universities <span class="text-success"></span></h1>
                    </div>
                </div>
            </div>

            <div class="grey-bg container">
                <section id="minimal-statistics">
                    <div class="row">
                        <div class="col-12 mt-3 mb-1">
                            <h4 class="text-uppercase">Our Universities</h4>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i1} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>We combine research with teaching, community with industry and heritage with innovation. Spark change and make a lasting difference with flexible, research-led degrees.</p>
                                            <a href="https://www.abdn.ac.uk/" target={'_blank'} rel="noreferrer">website</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i2} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Find out more about studying an undergraduate or postgraduate degree course at ARU, in Chelmsford, Cambridge, Peterborough and London.</p>
                                            <a href="https://www.aru.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i3} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Bangor University is steeped in history and offers excellent teaching. We're renowned for our world-leading research and our lecturers are experts in their ...</p>
                                            <a href="https://www.bangor.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i4} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Bath Spa University London is our new campus in Hackney and is part of our higher education community. · Flexible studying · Inspiring teaching · Dynamic learning.</p>
                                            <a href="https://www.bathspa.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i5} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Welcome to the University of Bedfordshire. Find information on all our courses including applying through UCAS for undergraduate courses, postgraduate, ...</p>
                                            <a href="https://www.beds.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i6} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>A world top 100 university and part of the prestigious Russell Group, the University of Birmingham makes important things happen.</p>
                                            <a href="https://www.birmingham.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i7} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>At Birmingham City University, we've invested £340 million transforming our campus and facilities and are committed to giving you the skills you need to ...</p>
                                            <a href="https://www.bcu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i8} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Study a wide range of college, undergraduate and postgraduate degree courses and higher level apprenticeships at University College Birmingham (UCB).</p>
                                            <a href="https://www.ucb.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i52} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Find your perfect course at a supportive, friendly university ranked 1se in the UK for graduates in employment (HESA: Graduate Outcomes 2023).</p>
                                            <a href="https://www.canterbury.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i9} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>We are a thriving multi-campus university in the south of England, UK. Explore our subjects and search for undergraduate or postgraduate degree courses.</p>
                                            <a href="https://www.brighton.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i10} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Cardiff Metropolitan University, UK & Ireland University of the Year 2021*. Times Higher Education Awards. Located in the lively capital city of Wales.</p>
                                            <a href="https://www.cardiffmet.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i11} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Official Coventry University website with information about undergraduate degrees, postgraduate degrees, student recruitment, the academic schools, ...</p>
                                            <a href="https://www.coventry.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i49} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Join us at Coventry University London. Immerse yourself in Coventry University London's global community and emerge as a talented, career-ready graduate ...</p>
                                            <a href="https://www.coventry.ac.uk/london/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i13} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>A UK University offering more than 300 courses at Bachelors and Masters level. Find out more about life at De Montfort University for International students. First Class Support.</p>
                                            <a href="https://www.dmu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i14} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Derby College is one of the University’s collaborative partners based in the UK. Find your place with us through Clearing.</p>
                                            <a href="https://www.derby.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i15} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Apply To The University Of Dundee, Your Best Choice For Undergraduate Study. An Amazing Student Experience Coupled With High Quality Teaching. Exercise Studios.</p>
                                            <a href="https://www.dundee.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i16} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of East Anglia is a home for everyone, a champion of interdisciplinarity that stands for equal opportunity in higher education.</p>
                                            <a href="https://www.uea.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i18} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Abertay University Dundee: Sunday Times University of the Year for Teaching Quality 2021. Browse our diverse range of courses and begin your journey today.</p>
                                            <a href="https://www.abertay.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i19} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of Exeter in Exeter, Devon, and Falmouth, Cornwall, UK, offers research and study in sciences, social sciences, business, humanities and ...</p>
                                            <a href="https://www.exeter.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i20} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Our Dedicated Team Are Happy To Help With Any Queries Regarding Your Application At GCU. Award-Winning Support For International Students At The University. Apply Today.</p>
                                            <a href="https://www.gcu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i21} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Study at University of Gloucestershire. ... Discover our courses. ... Our international offering is ranked in the top 15 in the UK according to the Whatuni ...</p>
                                            <a href="https://www.glos.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i22} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of Hertfordshire offers a wide range of Undergraduate, Postgraduate, Professional and Short courses. One of the top 200 universities in the ...</p>
                                            <a href="https://www.herts.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i23} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>University of Huddersfield - A university that is inspiring global professionals. TEF Gold Award and Times Higher Education University of Year 2013.</p>
                                            <a href="https://www.hud.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i24} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Manchester Met offers dedicated support and work placements on a range of courses. Be part of a proud history of higher education in Manchester since 1824. Masters programmes.</p>
                                            <a href="https://www.mmu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i25} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>USW is in an exceptional part of the UK, with city life, mountains and coast. With campuses in Cardiff, Newport and Pontypridd, you can enjoy all that south ...</p>
                                            <a href="https://www.southwales.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i26} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>We are a world-class, Global Top 125 university dedicated to excellence, creativity, and innovation through our teaching and pioneering research.</p>
                                            <a href="https://www.ncl.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i50} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Newcastle University Business School delivered a range of programmes to a UK and international community of talented postgraduate, undergraduate and pre-degree ...</p>
                                            <a href="https://www.ncl.ac.uk/london/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i27} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>En Suite Rooms and Budget-Friendly Options. From £79.80 Per Week. Rent Includes All Bills. Starting Uni In 2023? We Guarantee Accommodation To All Northumbria University...</p>
                                            <a href="https://www.northumbria.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i28} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of Nottingham is a pioneering institution. We're a top 20 UK university and 8th in the UK for research power. We're working to change the ...</p>
                                            <a href="https://www.nottingham.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i29} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>An incredible place to study. Student life. The best in the UK - voted for by students (Whatuni 2022). Our graduate promise. Employability support after you ...</p>
                                            <a href="https://www.ntu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i30} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>A prestigious Russell Group UK university, Queen's University Belfast is ranked in the top 200 universities in the world. (THE World University Rankings ...</p>
                                            <a href="https://www.qub.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i51} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Aberdeen's RGU is the Scottish University of the Year 2021. A top university in Scotland for graduate prospects, with an international reputation for ...</p>
                                            <a href="https://www.rgu.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i31} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Discover the University of Reading, a global university that enjoys a world-class reputation for teaching, research and enterprise.</p>
                                            <a href="https://www.reading.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i32} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Browse by subject area · Accounting and Finance · Applied Science · Art and Design · Biological Sciences · Business and Management · Chemistry · Computer Science ...</p>
                                            <a href="https://www.chester.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i34} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Based in Greater London, we offer a range of undergraduate and postgraduate degree courses, 'outstanding' teacher training and Olympic-standard sport ...</p>
                                            <a href="https://www.stmarys.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i53} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Solent University offers a wide range of courses to help students explore their interests and discover their passions. Embrace the new and apply through ...</p>
                                            <a href="https://www.solent.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i35} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of Stirling is a world-class institution with one of the best student experiences in the UK. Are you Stirling? Secure your place.</p>
                                            <a href="https://www.stir.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i36} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Clearing gives you another chance to find the best course for you. University of Portsmouth Clearing. Courses: Humanities, Science, Business and Law, Technology.</p>
                                            <a href="https://www.port.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i37} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>University of Wolverhampton offers undergraduate and postgraduate degree courses in health, education, business, arts, social sciences, ...</p>
                                            <a href="https://www.wlv.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i38} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of Surrey, shortlisted for University of the Year 2023, offers life-changing education and research on a beautiful campus in Guildford.</p>
                                            <a href="https://www.surrey.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i39} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>A university with a national and international reputation for excellence, innovation and research. Contributing to economic, social and cultural development ...</p>
                                            <a href="https://www.ulster.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i48} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>The University of business, practice and the professions.</p>
                                            <a href="https://www.city.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i40} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Dedicated Teams On Hand To Provide Learning, Employability, Funding and Wellbeing Support. With A Range Of UK City Campuses, Choose To Study Locally, Online Or Move...</p>
                                            <a href="https://www.law.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i41} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>UWE Bristol has a 76.8% student satisfaction score (The National Student Survey 2022) and 90.2% of our graduates are in work or further study 15 months ...</p>
                                            <a href="https://www.uwe.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i42} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Join our close-knit campus community based in the beautiful and historic city of Worcester. ... Travelling independently · Bringing dependants to the UK.</p>
                                            <a href="https://www.worcester.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i43} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Edinburgh. Extraordinary futures await. The University of Edinburgh is one of the world's top universities. Our entrepreneurial and cross-disciplinary ...</p>
                                            <a href="https://www.ed.ac.uk/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i44} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Study Group is a leading provider of international education, working with universities around the world including in the UK and Europe, North America and New ...</p>
                                            <a href="https://www.studygroup.com/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i45} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Navitas accelerates the internationalization of university campuses, providing greater opportunity and access to high-quality learning experiences and bringing ...</p>
                                            <a href="https://www.navitas.com/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i46} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>INTO helps students from around the world achieve their dream of studying overseas at university. We support international students to apply for entry to an ...</p>
                                            <a href="https://www.intostudy.com/en" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class=" d-flex">
                                <Accordion alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <img className='w-50' src={i47} alt="" />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Welcome to the QA's Higher Education website. Learn more about the different courses we offer and the partner universities we work with.</p>
                                            <a href="https://qahighereducation.com/" target={'_blank'} rel="noreferrer">website</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>


                    </div>
                </section>
            </div>

            <div className='container text-center p-3'>
                <h1>SGE Has Access To Most Of The <b>United States of America</b>, <b>New Zealand</b>, <b>Australia</b> And <b>Canada</b> Universities</h1>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i44} class="img-fluid" alt="Responsive Image" />
                        <p>Study Group is a for-profit education provider that prepares international students for university degree programmes and offers English language courses. The company operates in the United Kingdom.</p>
                    </div>
                </div>
            </div>

            <h1 className='text-center'>United States of America</h1>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s22} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s23} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s24} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s25} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s26} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s27} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s28} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s29} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s30} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Australia</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s1} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Ireland</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s2} class="img-fluid" alt="Responsive Image" />
                    </div>

                </div>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>Netherlands</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s3} class="img-fluid" alt="Responsive Image" />
                    </div>

                </div>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <h1 className='text-center'>New Zealand</h1>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s4} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s5} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>

            <div class="container">
                <h1 className='text-center'>United Kingdom</h1>
                <div class="row justify-content-center">
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s6} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s7} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s8} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s9} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s10} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s11} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s12} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s13} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s14} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s15} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s16} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s17} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s18} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s19} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s20} class="img-fluid" alt="Responsive Image" />
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center">
                        <img src={s21} class="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i45} class="img-fluid" alt="Responsive Image" />
                        <p>Give your students the opportunity to study at esteemed universities and colleges in countries across the globe with Navitas: the UK, the USA, Canada, Australia, the Netherlands, Germany, Singapore, New Zealand, UAE, and Sri Lanka. With a range of flexible entry points, diverse campuses, and hundreds of program options to choose from, Navitas caters to the needs of every student.</p>
                    </div>
                </div>

            </div>

            <div className='container testHeight '>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n2}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n3}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n4}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className=" w-100 "
                            src={n5}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3></h3>
                            <p></p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i46} class="img-fluid" alt="Responsive Image" />
                        <p>Every year we help thousands of international students from around the world achieve their dream of studying in the UK, US and China. We work with universities to create specialist programs designed specifically for international students.</p>
                    </div>
                </div>
            </div>

            <h1 className='text-center'>University list coming soon </h1>

            {/* ============ QA ================== */}
            
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h1></h1>
                        <img src={i47} class="img-fluid" alt="Responsive Image" />
                      
                    </div>
                </div>
            </div>
          

           {/*  <div class="container">
                
                <div class="row justify-content-center">
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to='https://qahighereducation.com/partner-institutions/ulster-university/' target="_blank"><img src={q1} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/northumbria-university/' target="_blank"><img src={q2} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/university-of-roehampton/' target="_blank"><img src={q3} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/middlesex-university/' target="_blank"><img src={q4} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/solent-university/' target="_blank"><img src={q5} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/london-metropolitan-university/' target="_blank"><img src={q6} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    <div class="m-1 col-12  col-sm-6 col-md-3 col-lg-3 col-xl-2 text-center">
                        <Link to= 'https://qahighereducation.com/partner-institutions/university-of-south-wales/' target="_blank"><img src={q7} class="img-fluid" alt="Responsive Image" /></Link>
                    </div>
                    
                </div>
            </div> */}




























            <Footer></Footer>
        </div>
    );
};

export default Universities;




/*







import i1 from '../../assets/Uni Logo update/1.png'
import i2 from '../../assets/Uni Logo update/2.jpg'
import i3 from '../../assets/Uni Logo update/3.jpg'
import i4 from '../../assets/Uni Logo update/4.png'
import i5 from '../../assets/Uni Logo update/5.png'
import i6 from '../../assets/Uni Logo update/6.jpg'
import i7 from '../../assets/Uni Logo update/7.jpg'
import i8 from '../../assets/Uni Logo update/8.png'
import i9 from '../../assets/Uni Logo update/9.jpg'
import i10 from '../../assets/Uni Logo update/10.jpg'
import i11 from '../../assets/Uni Logo update/11.jpg'
// import i12 from '../../assets/Uni Logo update/12.png'
import i13 from '../../assets/Uni Logo update/13.jpg'
import i14 from '../../assets/Uni Logo update/14.png'
import i15 from '../../assets/Uni Logo update/15.png'
import i16 from '../../assets/Uni Logo update/16.png'
// import i17 from '../../assets/Uni Logo update/17.png'
import i18 from '../../assets/Uni Logo update/18.png'
import i19 from '../../assets/Uni Logo update/19u.png'
import i20 from '../../assets/Uni Logo update/20.jpg'
import i21 from '../../assets/Uni Logo update/21.png'
import i22 from '../../assets/Uni Logo update/22.png'
import i23 from '../../assets/Uni Logo update/23.png'
import i24 from '../../assets/Uni Logo update/24.jpg'
import i25 from '../../assets/Uni Logo update/25.jpg'
import i26 from '../../assets/Uni Logo update/26.png'
import i27 from '../../assets/Uni Logo update/27.jpg'
import i28 from '../../assets/Uni Logo update/28.jpg'
import i29 from '../../assets/Uni Logo update/29.jpg'
import i30 from '../../assets/Uni Logo update/30.jpg'
import i31 from '../../assets/Uni Logo update/31.png'
import i32 from '../../assets/Uni Logo update/32.jpg'
// import i33 from '../../assets/Uni Logo update/33.png'
import i34 from '../../assets/Uni Logo update/34.png'
import i35 from '../../assets/Uni Logo update/35.jpg'
import i36 from '../../assets/Uni Logo update/36.jpg'
import i37 from '../../assets/Uni Logo update/37.png'
import i38 from '../../assets/Uni Logo update/38.png'
import i39 from '../../assets/Uni Logo update/39.jpg'
import i40 from '../../assets/Uni Logo update/40.jpg'
import i41 from '../../assets/Uni Logo update/41.png'
import i42 from '../../assets/Uni Logo update/42.jpg'
import i43 from '../../assets/Uni Logo update/43.png'
import i44 from '../../assets/Uni Logo update/44.png'
import i45 from '../../assets/Uni Logo update/45.jpg'
import i46 from '../../assets/Uni Logo update/46.png'
import i47 from '../../assets/Uni Logo update/47.jpg'
import i48 from '../../assets/Uni Logo update/25.png'
import i49 from '../../assets/Uni Logo update/48.jpg'
import i50 from '../../assets/Uni Logo update/49.png'
import s1 from '../../assets/Uni Logo update/studyGroup/s1.svg'
import s2 from '../../assets/Uni Logo update/studyGroup/s2.webp'
import s3 from '../../assets/Uni Logo update/studyGroup/s3.webp'
import s4 from '../../assets/Uni Logo update/studyGroup/s4.svg'
import s5 from '../../assets/Uni Logo update/studyGroup/s5.svg'
import s6 from '../../assets/Uni Logo update/studyGroup/s6.svg'
import s7 from '../../assets/Uni Logo update/studyGroup/s7.svg'
import s8 from '../../assets/Uni Logo update/studyGroup/s8.svg'
import s9 from '../../assets/Uni Logo update/studyGroup/9.svg'
import s10 from '../../assets/Uni Logo update/studyGroup/10.svg'
import s11 from '../../assets/Uni Logo update/studyGroup/11.svg'
import s12 from '../../assets/Uni Logo update/studyGroup/12.svg'
import s13 from '../../assets/Uni Logo update/studyGroup/13.svg'
import s14 from '../../assets/Uni Logo update/studyGroup/14.svg'
import s15 from '../../assets/Uni Logo update/studyGroup/15.svg'
import s16 from '../../assets/Uni Logo update/studyGroup/16.svg'
import s17 from '../../assets/Uni Logo update/studyGroup/17.webp'
import s18 from '../../assets/Uni Logo update/studyGroup/18.svg'
import s19 from '../../assets/Uni Logo update/studyGroup/19.svg'
import s20 from '../../assets/Uni Logo update/studyGroup/20.svg'
import s21 from '../../assets/Uni Logo update/studyGroup/21.webp'
import s22 from '../../assets/Uni Logo update/studyGroup/22.svg'
import s23 from '../../assets/Uni Logo update/studyGroup/23.svg'
import s24 from '../../assets/Uni Logo update/studyGroup/24.svg'
import s25 from '../../assets/Uni Logo update/studyGroup/25.svg'
import s26 from '../../assets/Uni Logo update/studyGroup/26.svg'
import s27 from '../../assets/Uni Logo update/studyGroup/27.svg'
import s28 from '../../assets/Uni Logo update/studyGroup/28.svg'
import s29 from '../../assets/Uni Logo update/studyGroup/29.svg'
import s30 from '../../assets/Uni Logo update/studyGroup/30.svg'
// navitas
import n1 from '../../assets/Uni Logo update/navitas/1.jpg'
import n2 from '../../assets/Uni Logo update/navitas/2.jpg'
import n3 from '../../assets/Uni Logo update/navitas/3.jpg'
import n4 from '../../assets/Uni Logo update/navitas/4.jpg'
import n5 from '../../assets/Uni Logo update/navitas/5.jpg'




















*/