import React from 'react';
import './CounterSec.css'
import CountUp from 'react-countup';
import Container from 'react-bootstrap/Container';
import { FaBuilding } from 'react-icons/fa';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa';
import { MdCountertops } from 'react-icons/md';
import { FaUniversity } from 'react-icons/fa';
import { BiBook } from 'react-icons/bi';
import { FaUserGraduate } from 'react-icons/fa';
// import { FaClipboardCheck } from 'react-icons/fa';

const CounterSec = () => {
    return (
        <Container fluid >
            <div class="services-section">
                <div class="inner-width">
                    <div class="services-container">
                        <div class="service-box ">
                            <div class="service-icon">
                            <FaBuilding className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Global Offices</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={16} /></font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <FaChalkboardTeacher className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Global Counsellors</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={350} />+</font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <FaGraduationCap className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >UK Education Fair</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={100} />+</font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <MdCountertops className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Virtual Events/Seminars</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={600} />+</font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <FaUniversity className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Recruiting University</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={150} />+</font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <BiBook className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Courses Offered</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={50000} />+</font></font></div>
                        </div>
                        <div class="service-box ">
                            <div class="service-icon">
                            <FaUserGraduate className='myServiceFont' />
                            </div>
                            <div class="service-title"><font ><font >Student Served</font></font></div>
                            <div class="service-title-one"><font ><font ><CountUp delay={3} end={145000} />+</font></font></div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default CounterSec;
