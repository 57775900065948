import React, { useEffect, useState } from 'react';
import bg from '../../assets/blogBg.jpg'
import Map from '../../Shared/Map/Map';
import Footer from '../Footer/Footer';
import HeaderNav from '../HeaderNav/HeaderNav';
import FormContactUs from '../../Shared/Form/FormContactUs/FormContactUs';
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [loading, setLoading] = useState(true);

    const handleLoad = () => {
        setLoading(false);
    }
    return (
        <div>

            <HeaderNav></HeaderNav>
            <div class="container-fluid mt-5 bg-light bg-gradient shadow ">
                <div class="p-4 p-md-5 mb-4 text-white rounded featured" style={{
                    backgroundImage: `url(${bg})`,
                }}>
                    <div class="col-md-12 px-0">
                        <h1 class="pt-5 display-4 font-italic text-center">Contact <span class="text-success"></span>  Us</h1>
                    </div>
                </div>
            </div>


            <Map></Map>
            {/* <FormContactUs /> */}


            <div className='container-fluid myServiceBg slide-from-right-to-left'>
                    <h1 className='text-center text-white'>Contact Us</h1>
                </div>

            <div style={{ position: 'relative', width: '100%', height: '1090px', paddingBottom: '56.25%' }}>
                {loading && (
                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
                        <p>Loading.....</p>
                    </div>
                )}
                <iframe
                    src="https://app.shabujglobal.com/registration/student"
                    scrolling="no"
                    style={{ position: 'absolute', width: '100%', height: '1090px', left: 0, top: 0 }}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    onLoad={handleLoad}

                >
                    Loading…
                </iframe>
            </div>

            <Footer></Footer>
        </div>
    );
};

export default ContactUs;

/*

            <div style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
                <iframe
                    src="https://app.shabujglobal.com/registration/student"
                    style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                >
                    Loading…
                </iframe>
            </div>


*/